<template>
  <div id="snap_body" class="body">
    <div id="top" class="page" v-fragments>
      <section>
        <home :pageMessages="pageMessages" :thisPage="0" :isMobile="true"/>
      </section>
    </div>

    <section class="snap page" style="background: white;">
      <photography :pageMessages="pageMessages" :thisPage="1" :isMobile="true"/>
    </section>

    <section class="snap page">
      <film-mobile :pageMessages="pageMessages" :thisPage="2"/>
    </section>

    <section class="snap page music_mobile">
      <music-mobile :pageMessages="pageMessages" :thisPage="3" :isMobile="true"/>
    </section>

    <section class="snap page" style="background: white; box-shadow: inset 0px 9px 11px -9px rgba(0, 0, 0, 0.75) !important;">
      <LF :pageMessages="pageMessages" :thisPage="4" :isMobile="true"/>
    </section>

    <div id="hoverArea" @mouseover="dropdown = true"></div>
    <div id="navbar" class="fixme basicNav" :class="{blackTheme: currentPage > 2}" ref="navbar" @mouseover="dropdown = true" @mouseleave="dropdown = false">
      <div id="bar" ref="bar" :class="{transparentBack: currentPage == 0}"></div>
      <div id="currentPageContainer"><div id="currentPage" ref="currentPage"></div></div>
      <ul id="navlist" ref="navlist" class="noDisplay" @mouseover="dropdown = true">
        <li :class="{ activeTab: currentPage == 1}" class="noOpacity" @click="scrollToPage(1)">PHOTOGRAPHY</li>
        <li :class="{ activeTab: currentPage == 2}" class="noOpacity" @click="scrollToPage(2)">FILM</li>
        <li :class="{ activeTab: currentPage == 3}" class="noOpacity" @click="scrollToPage(3)">MUSIC</li>
        <li :class="{ activeTab: currentPage == 4}" class="noOpacity" @click="scrollToPage(4)">LF</li>
      </ul>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */

import $ from 'jquery'

import smoothscroll from 'smoothscroll-polyfill';

import Home from './Home.vue'
import FilmMobile from './FilmMobile.vue'
import LF from './LF.vue'
import MusicMobile from './MusicMobile.vue'
import Photography from './Photography.vue'

export default {
  name: 'HOME',
  props: {
    browser: String
  },
  directives: {
    fragments: {
      inserted: function(el) {
        const parent = document.body;
        parent.insertBefore(el, parent.firstChild);
      }
    }
  },
  
  components: {
    Home,
    FilmMobile,
    LF,
    MusicMobile,
    Photography
  },
  
  data: function() {
    return {
      pageMessages: {
        0: ["scroll_to_about", "scroll_left_about"],
        1: ["scroll_to_photography", "scroll_left_photography"],
        2: ["scroll_to_film", "scroll_left_film"],
        3: ["scroll_to_music", "scroll_left_music"],
        4: ["scroll_to_lf", "scroll_left_lf"], 
      },

      pageMap: {
        0: "",
        1: "PHOTOGRAPHY",
        2: "FILM",
        3: "MUSIC",
        4: "LF"
      },
      currentPage: 0,
      lastScrollPos: 0,
      pagePositionsTop: [],
      pagePositionsBottom: [],
      dropdown: false,
      scrollToOn: false,
      fixNav: false,
    }
  },

  watch: {
    'currentPage': async function(newPage, oldPage) {
      if (newPage != oldPage) {
        if (!this.dropdown) {
          this.$refs.currentPage.classList.add("fadeText");
          await this.sleep(100);
          this.$refs.currentPage.innerText = this.pageMap[newPage];
          this.$refs.currentPage.classList.remove("fadeText");
        } else {
          this.$refs.currentPage.innerText = this.pageMap[newPage];
        }

        if (newPage == 1 && oldPage == 0) {
          this.scrollToSnaps();
        }

        if (newPage == 1 && oldPage == 2) {
          this.scrollToSnaps();
        }

        if (newPage >= 2) {
          document.body.classList.add("noOverflow");
        } else {
          document.body.classList.remove("noOverflow");
        }

        // if (newPage == 1 && oldPage > 1) {
        //   // document.body.classList.remove("noScroll");
        // }

        // if (newPage > 1) {
        //   // document.body.classList.add("noScroll");
        // }
        
        
        this.$root.$emit(this.pageMessages[oldPage][1]);
        
        this.$root.$emit(this.pageMessages[newPage][0]);
      }
    },

    'dropdown': async function(newDrop, oldDrop) {
      // if (newDrop == oldDrop) {
      //   return;
      // } 
      let elems = this.$refs.navlist.getElementsByTagName('li');
      if (newDrop == true) {
        this.$refs.currentPage.classList.add("fadeText");
        this.$refs.navbar.classList.remove("basicNav");
        for (let x=0; x<2; x++) {
          this.$refs.navlist.classList.remove("noDisplay");
          await this.sleep(50);
          for (let elem of elems) {
            elem.classList.remove("noOpacity");
            await this.sleep(200);
          }
          if (this.$refs.navlist.classList.contains("noDisplay")) {
            for (let elem of elems) {
              elem.classList.add("noOpacity");
            }
            continue;
          }
          break;
        }
        
      } else {
        this.$refs.navbar.classList.add("basicNav");
        for (let z=elems.length-1; z>=0; z--) {
          elems[z].classList.add("noOpacity");
          // await this.sleep(200);
        }
        this.$refs.currentPage.classList.remove("fadeText");
        await this.sleep(500);
        this.$refs.navlist.classList.add("noDisplay");
      }
    }
  },

  created() {
    // console.log("BROWSER: " + this.browser);
  },

  mounted: async function() {
    /* eslint-disable no-unused-vars */

    let path = window.location.pathname.substring(1);
    if (path.indexOf('?') != -1) {
      path = path.substring(0, path.indexOf('?'));
    }
    if (path.charAt(path.length-1) == '/') {
      path = path.substring(0, path.length - 1);
    }
    

    if (path == 'PHOTOGRAPHY') {
      $('html, body').animate({
        scrollTop: document.getElementById('app').offsetTop
      }, 1000);
      await this.sleep(2000);
      this.currentPage = 1;
    } else if (path == 'FILM') {
      $('#snap_body').animate({
          scrollTop: document.getElementsByTagName('section')[2].offsetTop - document.getElementById('snap_body').offsetTop
        }, 100);
      $('html, body').animate({
        scrollTop: document.getElementById('app').offsetTop
      }, 1000);
      await this.sleep(2000);
      this.currentPage = 2;
    } else if (path == "MUSIC") {
      $('#snap_body').animate({
          scrollTop: document.getElementsByTagName('section')[3].offsetTop - document.getElementById('snap_body').offsetTop
        }, 100);
      $('html, body').animate({
        scrollTop: document.getElementById('app').offsetTop
      }, 1000);
      await this.sleep(2000);
      this.currentPage = 3;
    } else if (path == "LF") {
      $('#snap_body').animate({
          scrollTop: document.getElementsByTagName('section')[4].offsetTop - document.getElementById('snap_body').offsetTop
        }, 100);
      $('html, body').animate({
        scrollTop: document.getElementById('app').offsetTop
      }, 1000);
      await this.sleep(2000);
      this.currentPage = 4;
    }

    this.resizeUpdate();
    this.handleScrollMobile();
    document.addEventListener('scroll', this.handleScrollMobile);
    document.getElementById('snap_body').addEventListener('scroll', this.handleScrollMobile);
    window.addEventListener("resize", this.resizeUpdate);
    await this.sleep(1000);
    smoothscroll.polyfill();
  },

  methods: {
    sleep(milliseconds) {
      return new Promise(function(resolve, reject) {
        setTimeout(resolve, milliseconds, true);
      });
    },

    getBrowser() {
      let isSafari = false;
      var iOS = false;
      try {
        isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotifications));
      } catch {
        isSafari = false
      }
      
      try {
        iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
      } catch {
        iOS = false
      }

      if (isSafari || iOS) {
        return 'safari';
      } else {
        return 'chrome';
      }
    },

    scrollToSafari(offset, callback) {
      document.removeEventListener('scroll', this.handleScrollMobile);
      this.scrollTo = true;
      const fixedOffset = offset.toFixed(),
          onScroll = function () {
              if (window.pageYOffset.toFixed() === fixedOffset) {
                  window.removeEventListener('scroll', onScroll)
                  callback()
              }
          }

      window.addEventListener('scroll', onScroll)
      onScroll()
      window.scrollTo({
          top: offset,
          behavior: 'smooth'
      })
    },

    scrollToCustom: async function(offset) {
      $('html, body').animate({
        scrollTop: offset
      }, 500);
      this.scrollToSafariOff();
    },

    scrollToSafariOff: async function() {
      await this.sleep(600);
      document.addEventListener('scroll', this.handleScrollMobile);
      document.getElementById('snap_body').addEventListener('scroll', this.handleScrollMobile);
      document.getElementById('app').classList.remove("noSnap");
      this.scrollTo = false;
    },

    scrollToPage: async function(page) {
      if (this.scrollTo) {
        return;
      }

      this.scrollTo = true;
      document.removeEventListener('scroll', this.handleScrollMobile);
      document.getElementById('snap_body').removeEventListener('scroll', this.handleScrollMobile);
      document.getElementById('app').classList.add("noSnap");
      if (page == 0) {
        this.scrollToCustom(0);
      } else {
        // $('html, body').animate({
        //   scrollTop: document.getElementById('snap_body').offsetTop
        // }, 500);
        $('#snap_body').animate({
          scrollTop: document.getElementsByTagName('section')[page].offsetTop - document.getElementById('snap_body').offsetTop
        }, 500);
        this.scrollToSafariOff();
      }
    },

    scrollToSnaps: async function() {
      if (this.scrollTo) {
        return;
      }
      this.scrollTo = true;
      document.removeEventListener('scroll', this.handleScrollMobile);
      document.getElementById('snap_body').removeEventListener('scroll', this.handleScrollMobile);
      document.getElementById('app').classList.add("noSnap");
      // this.scrollToSafari(document.getElementById('app').offsetTop, this.scrollToSafariOff.bind(this));
      this.scrollToCustom(document.getElementById('app').offsetTop);
      // if (this.browser == 'safari') {
      //   document.getElementById('app').classList.add("noSnap");
      //   await this.sleep(100);
      //   this.scrollToSafari(document.getElementById('app').offsetTop, this.scrollToSafariOff.bind(this));
      // } else {
      //   window.scrollTo({ left: 0, top: document.getElementById('app').offsetTop, behavior: 'smooth'});
      // }
    },

    handleScrollMobile() {
      if (this.scrollTo) {
        return;
      }
      var currentScroll = $('html').scrollTop();
      var snaps_offset = document.getElementById('app').offsetTop;
      if (currentScroll >= snaps_offset) {
        currentScroll = document.getElementById('snap_body').scrollTop + snaps_offset;
      }

      if (currentScroll >= this.lastScrollPos) {
        var bottomCurrentScroll = currentScroll + window.innerHeight;
        if (bottomCurrentScroll >= snaps_offset) {
          for (let i=4; i>=0; i--) {
            let section = document.getElementsByTagName('section')[i];
            if (bottomCurrentScroll > section.offsetTop) {
              this.currentPage = i;
              break;
            }
          }
        } else {
          this.currentPage = 0;
        }
      } else {
        for (let i=4; i>=0; i--) {
          try {
            if (currentScroll >= document.getElementsByTagName('section')[i].offsetTop) {
              this.currentPage = i;
              break;
            }
          } catch(err) {
            console.log(i);
          }
        }
      }
      // console.log("PAGE: " + this.currentPage);
      this.lastScrollPos = currentScroll;
      
    },

    resizeUpdate: async function() {
      var tmp_positions_top = [];
      var tmp_positions_bottom = [];
      for (let elem of document.getElementsByClassName('page')) {
        tmp_positions_top.push(elem.offsetTop);
        tmp_positions_bottom.push(elem.offsetTop + elem.offsetHeight);
      }
      this.pagePositionsTop = tmp_positions_top;
      this.pagePositionsBottom = tmp_positions_bottom;

      // let emptySpaceBox = document.getElementsByClassName('innerText')[1].getBoundingClientRect();
      // document.getElementById('about_words').style.setProperty("width", (emptySpaceBox.width) + "px");
    }

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.body {
  scroll-snap-type: y mandatory;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
}

.snap {
  scroll-snap-align: start;
  scroll-snap-stop: always;
  min-height: 100vh;
  position: relative;
}

section {
  box-shadow: -2px -3px 15px 2px rgba(0, 0, 0, 0.75), inset 0px 9px 11px -9px rgba(0, 0, 0, 0.75);
}

.noScroll {
  overflow-y: hidden !important;
}

.noSnap {
  scroll-snap-type: none !important;
}

.fixme {
  top: 0%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: 100%;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  position: fixed;
  left: 1%;
  background: transparent;
  font-family: helvetica;
  /* top: 50%; */
  /* left: 50%; */
  /* transform: translate(0%, -50%); */
  transition: 1s;
  /* height: 100vh; */
  width: 10%;
  cursor: default;
}

.fixme ul {
  padding: 0;
  list-style-type: none;
  overflow: hidden;
  list-style-type: none;
  overflow: hidden;
  display: grid;
  justify-content: space-evenly;
  flex-wrap: wrap;
  padding: 0;
  /* margin: 0; */
  list-style: none;
  /* background-color: #536453; */
  text-align: left;
  margin: revert !important;
  height: 80%;
  top: 50%;
  transform: translate(0%, -50%);
  position: absolute;
}

.fixme li {
  float: left;
  padding: 1rem;
  padding-left: 0;
  width: auto !important;
  /* margin-left: auto; */
  margin-top: auto;
  margin-bottom: auto;
  color: rgb(192, 192, 192);
  text-align: left;
  cursor: default;
  transition: 0.5s ease;
  font-size: 0.5em;
  letter-spacing: 3px;
  margin-left: 1rem;
  cursor: pointer;
}

.fixme li:hover {
  color: rgba(255,255,255,0.8);
}

#bar {
  width: 5%;
  height: inherit;
  position: absolute;
  background: white;
  /* box-shadow: 1px 1px 3px black; */
  transition: 1s;
  height: 80%;
  top: 50%;
  transform: translate(0%, -50%);
  position: absolute;
}

.activeTab {
  color: white !important;
  /* transition: color 1s !important; */
}

.fixmePinned {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  box-shadow: 0px 1px 3px rgba(0,0,0,0.7);
}

.basicNav {
  transition: 1s;
  top: 3%;
  transform: unset;
  height: 3vh;
  width: 10%;
}

.basicNav #bar {
  width: 4%;
  height: inherit;
  position: absolute;
  background: white;
}

#currentPageContainer {
  text-align: center;
  display: table;
  height: inherit;
  margin-left: 1rem;
}

#currentPage {
  vertical-align: middle;
  display: table-cell;
  /* margin-left: 1rem; */
  font-family: helvetica;
  letter-spacing: 3px;
  /* opacity: 0.1; */
  font-size: 0.5rem;
  color: rgba(0,0,0,0.8);
  color: white;
  /* text-shadow: 1px 1px 0px black;*/
  transition: all 0.5s ease, opacity 0.1s; 
}

.blackTheme #bar {
  background: black !important;
}

.blackTheme #currentPage {
  color: black;
}

.blackTheme li {
  color: rgb(121, 121, 121);
}

.blackTheme .activeTab {
  color: black !important;
}

.basicNav .transparentBack {
  background: transparent !important;
  transition: background-color 0.1s;
}

.fadeText {
  transform: translateX(-3rem);
  opacity: 0;
}

#hoverArea {
  position: fixed;
  top: 0;
  width: 10vw;
  height: 10vh;
}
</style>
