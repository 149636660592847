<template>
  <div id="mobile_music" class="body">
    <div id="playbox" ref="playbox" class="noShow">
        <div id="playstuff">
            <div id="nowplaying" ref="nowplaying" @click="nowplayingClick()" class="ellipses"></div>
            <div id="shareLink" ref="shareLink" @click="shareSong()" class=""><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-share"><path d="M4 12v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-8"/><polyline points="16 6 12 2 8 6"/><line x1="12" y1="2" x2="12" y2="15"/></svg></div>
            <div id="playsBox" ref="playsBox" class="noShow"><div id="plays" ref="playsElem"></div><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-3 17v-10l9 5.146-9 4.854z"/></svg></div>
        </div>
        <div id="audioControls">
            <div id="prevBtn" @click="lastSong()"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M14 12l-14 9v-18l14 9zm-4-9v4l8.022 5-8.022 5v4l14-9-14-9z"/></svg></div>
            <div id="playBtn" ref="playBtn" @click="playBtnClick()"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M3 22v-20l18 10-18 10z"/></svg></div>
            <div id="playLoader" ref="playLoader" class="spinner noDisplay">
                <div class="rect1"></div>
                <div class="rect2"></div>
                <div class="rect3"></div>
                <div class="rect4"></div>
                <div class="rect5"></div>
            </div>
            <div id="nextBtn" @click="skipSong()"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M14 12l-14 9v-18l14 9zm-4-9v4l8.022 5-8.022 5v4l14-9-14-9z"/></svg></div>
        </div>
        <div id="all_tracks" ref="all_tracks">
            <div id="theTracks" ref="theTracks">
                <div id="tracksCarousel"></div>
            </div>
            <!-- <ul id="tracks">
            </ul> -->
        </div>
        <div id="dropdown" ref="dropdownArrow" class="" @click="dropdown()"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z"/></svg></div>
    </div>

    <div id="trackbox">
        <div id="myCarousel" ref="carousel" class="cv-carousel"></div>
        <!-- <ul id="myList"></ul> -->
    </div>

    <div id="prevPage" ref="prevPageBtn" @click="changePage(-1)"><svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 404.258 404.258" style="enable-background:new 0 0 404.258 404.258;" xml:space="preserve"><polygon points="202.129,114.331 0,265.927 18,289.927 202.129,151.831 386.258,289.927 404.258,265.927 "/><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></svg></div>
    <div id="nextPage" ref="nextPageBtn" @click="changePage(1)"><svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 404.257 404.257" style="enable-background:new 0 0 404.257 404.257;" xml:space="preserve"><polygon points="386.257,114.331 202.128,252.427 18,114.331 0,138.331 202.128,289.927 404.257,138.331 "/><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></svg></div>
    
    <div id="info_out">
        <div id="info" ref="info" class="noShow"></div>
    </div>

    <div id="wrapper">
        <audio id="mytrack" ref="mytrack" ></audio>
    </div>

  </div>
</template>


<script>
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable no-redeclare */

import $ from 'jquery'

export default {
  name: 'MUSIC',
  props: {
    pageMessages: Object,
    thisPage: Number,
    isMobile: Boolean
  },

  data: function() {
    return {
      albums: [],
      album_index: -1,
      track_index: 0,
      updateTime: null,
      album_plays: {},
      is_playing: false,
      first_click: false,
      gotAlbumPlays: false,
      dropped: false,
      lastScrollVal: -1,
      scrollMode: 0,
      scrolldelay: null,
      scrolldelay2: null,
      covers: [],
      devMode: false,
      devLocal: false,
      browser: null,
      q_album_index: -1,
      q_track_index: -1,
      current_page: -1,
      num_pages: -1,
      fixingTime: false,
      load_is_playing: false,
      tracksCarousel: null,
      Eclipse: null
      
    }
  },

  computed: {
    currentURL: function () {
      return new URL(window.location.href);
    },

    q_album: function() {
        return this.currentURL.searchParams.get("album");
    },

    q_track: function() {
        return this.currentURL.searchParams.get("track");
    },

    vh: function() {
        return window.innerHeight * 0.01;
    }
  },

  created() {
    if (this.isMobile) {
      import('../assets/music/mobile_style.css');
    }
    
    var defaults_options = {
        items: 4,
        margin: 10,
        nav: true,
        navText: ['prev', 'next'],
    };
	
	$.fn.extend({
		carouselVertical: function(argumentss){
			
			var carouselVerticalClass = function(element, options){
				
				var _options = {},
					$this = null,
					$stage = null,
					$items = null,
					_this = null,
					_height = 0,
					_stage_height = 0,
					_items_height = 0,
					_items_outer_height = 0,
					_items_count = 0,
					_current_item = 1,
					_current_item_pos = 1,
					_last_item = 1,
					_last_item_pos = 1;
				
				function init(el, opts){
					_this = el;
					$this = $(_this);
					if($this.hasClass('cv-loaded'))
						return false;
					_options = opts;
					$items = $this.find('.item');
					_items_count = $items.length;
					if(_items_count == 0)
						return false;
					_height = $this.height();
					if(_items_count < _options.items) _options.items = _items_count;
					$this.wrapInner('<div class="cv-stage-outer"><div class="cv-stage"></div></div>');
					// drag and drop (for touchscreens) always active
					$this.addClass('cv-drag');
					// explicit height for position relative
					$this.height(_height);
					$items.wrap('<div class="cv-item"></div>');
					$items = $this.find('.cv-item');
					$items.slice(0, _options.items).addClass('active');
					_items_height = parseInt((_height - _options.margin * _options.items) / _options.items);
					$items.css({
						'height': _items_height + 'px',
						'margin-bottom': _options.margin + 'px'
					});
					_items_outer_height = _items_height + _options.margin;
					_stage_height = _items_outer_height * _items_count;
					_last_item = _items_count - _options.items;
					_last_item_pos = -_last_item * _items_outer_height;
					$stage = $this.find('.cv-stage');
					$items = $this.find('.cv-item');
					$this.addClass('cv-loaded');
					// _this.addEventListener('touchstart', touchHandler, true);
					// _this.addEventListener('MSPointerDown', touchHandler, true);
					// _this.addEventListener('touchmove', touchHandler, true);
					// _this.addEventListener('MSPointerMove', touchHandler, true);
					// _this.addEventListener('touchend', touchHandler, true);
					// _this.addEventListener('MSPointerUp', touchHandler, true);
					// _this.addEventListener('touchcancel', touchHandler, true);
					// _this.addEventListener('mousedown', mouseHandler, false);
					// _this.addEventListener('mousemove', mouseHandler, false);
					// _this.addEventListener('mouseup', mouseHandler, false);
					if(_options.nav){
						$this.prepend('<div class="cv-nav"><div class="cv-prev">' + _options.navText[0] + '</div><div class="cv-next">' + _options.navText[1] + '</div></div>');
						var prev_btn = $this.find('.cv-prev')[0],
							next_btn = $this.find('.cv-next')[0];
						// prev_btn.addEventListener('touchend', touchHandler, true);
						// prev_btn.addEventListener('MSPointerUp', touchHandler, true);
						// prev_btn.addEventListener('click', prevClick, false);
						// next_btn.addEventListener('touchend', touchHandler, true);
						// next_btn.addEventListener('MSPointerUp', touchHandler, true);
						// next_btn.addEventListener('click', nextClick, false);
					}
					$this.on('goTo', goTo);
					moveTo(0);
					return true;
				}
				
				if(!init(element, options))
					return false;
				
				var is_mousedown = false;
				function mouseHandler(e){
					var diff = e.clientY - _current_item_pos;
					switch(e.type){
						case 'mousedown':
							is_mousedown = true;
							_current_item_pos = diff;
						break;
						case 'mousemove':
							if(is_mousedown)
								moveByMouse(diff);
							break;
						case 'mouseup':
						default:
							if(is_mousedown) {
								if(diff > 0) diff = 0;
								moveFinal(diff);
								is_mousedown = false;
							}
					}
				}
				var clickms = 100,
					lastTouchDown = -1;
				function touchHandler(e){
					// https://stackoverflow.com/questions/5186441/javascript-drag-and-drop-for-touch-devices#6362527
					var touch = e.changedTouches[0],
						simulatedEvent = document.createEvent('MouseEvent'),
						d = new Date(),
						type = null;
					switch(e.type){
						case 'touchstart':
							type = 'mousedown';
							lastTouchDown = d.getTime();
							break;
						case 'touchmove':
							type = 'mousemove';
							lastTouchDown = -1;
							break;        
						case 'touchend':
							if(lastTouchDown > -1 && (d.getTime() - lastTouchDown) < clickms){
								lastTouchDown = -1;
								type = 'click';
								break;
							}
							type = 'mouseup';
							break;
						case 'touchcancel':
						default:
							return;
					}
					simulatedEvent.initMouseEvent(type, true, true, window, 1, touch.screenX, touch.screenY, touch.clientX, touch.clientY, false, false, false, false, 0, null);
					touch.target.dispatchEvent(simulatedEvent);
					e.preventDefault();
				}
				function moveByMouse(pos){
					if(pos > 0) pos /= 5;
					if(pos < _last_item_pos) pos = _last_item_pos + (pos - _last_item_pos) / 5;
					$stage.css('transition', 'none');
					$this.addClass('cv-grab');
					move(pos);
				}
				function moveFinal(pos){
					$stage.css('transition', 'all 0.25s ease');
					$this.removeClass('cv-grab');
					moveTo(Math.round(-pos / _items_outer_height));
				}
				function move(pos){
					$stage.css('transform', 'translateY(' + pos + 'px)');
				}
				// 1: first element
				function goTo(e, n){
					if(typeof n != 'undefined' && $.isNumeric(n) && Math.floor(n) == n)
						moveTo(n - 1);
				}
				// 0: first element
				function moveTo(n){
					if(n < 0) n = 0;
					if(n > _last_item) n = _last_item;
					_current_item = n;
					$items.removeClass('active');
					$items.slice(n, (_options.items + n)).addClass('active');
					_current_item_pos = -_items_outer_height * n;
					move(_current_item_pos);
				}
				/*
				replaced by css transition
				function slideTop(pos){
					var mem = pos,
						interval = setInterval(function(){
							mem -= 10;
							if(mem < 0){
								mem = 0;
								clearInterval(interval);
							}
							move(mem);
						}, 1);
				}
				*/
				function prevClick(){
					moveTo(_current_item - 1);
				}
				function nextClick(){
					moveTo(_current_item + 1);
				}
			};
			
			function init(el, args){
				if(typeof args == 'object' || typeof args == 'undefined'){
					var options = $.extend({}, defaults_options, args);
					carouselVerticalClass(el, options);
				}
			}
			
			var length = this.length;
			if(length < 1) return this;
			if(typeof argumentss == 'undefined') argumentss = defaults_options;
			if(length > 1)
				for(var c = 0; c < length; c++)
					init(this[c], argumentss);
			else
				init(this[0], argumentss);
			return this;
		}
    });


    this.browser = this.getBrowser();
  },

  mounted() {
    this.$root.$on(this.pageMessages[this.thisPage][0], this.scrolledTo);
    this.$root.$on(this.pageMessages[this.thisPage][1], this.scrolledAway);

    this.covers = document.getElementsByClassName("slideMe");
    this.audioApp()
    this.$refs.mytrack.addEventListener("pause", this.mytrackPause);
    this.$refs.mytrack.addEventListener("play", this.mytrackPlay);
  },
  methods: {
    scrolledTo() {
    },
    scrolledAway() {
    },

    sleep(milliseconds) {
        return new Promise(function(resolve, reject) {
            setTimeout(resolve, milliseconds, true);
        });
    },

    trackName(track) {
        return track.substring(track.lastIndexOf('/')+1, track.lastIndexOf('.'));
    },

    getBrowser() {
        let isSafari = false;   
        var iOS = false;
        try {
            isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotifications));
        } catch {
            isSafari = false
        }
        
        try {
            iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
        } catch {
            iOS = false
        }

        if (isSafari || iOS) {
            return 'safari';
        } else {
            return 'chrome';
        }
    },

    update() {
        let mytrack = document.getElementById("my")
        if(!this.$refs.mytrack.ended){
            if (this.track_index + 1 < this.albums[this.album_index].tracks_data.length && this.$refs.mytrack.currentTime > this.albums[this.album_index].tracks_data[this.track_index+1][this.browser].start) {
                this.track_index += 1;
                this.$refs.nowplaying.innerText = this.albums[this.album_index].tracks_data[this.track_index].name;
                document.title = this.albums[this.album_index].tracks_data[this.track_index].name + " - " + this.albums[this.album_index].artist;
                this.incrementPlay();
                this.fixFirstPlay();
            }
            
        }
        else{
            window.clearInterval(this.updateTime);
        }
    },

    getAlbums() {
        return new Promise(function(resolve,reject) {
            fetch("https://music.gproductions.org/API/getAlbums?mobile=true", {method: 'get', headers: { "Content-Type": "application/json" }})
            .then((res) => res.json())
            .then((data) => {
                resolve(data);
            });
        })
    },

    skipSong(){
        if (this.track_index + 1 >= this.albums[this.album_index].tracks_data.length) {
            return;
        }
        this.track_index += 1;
        this.$refs.mytrack.currentTime=this.albums[this.album_index].tracks_data[this.track_index][this.browser].start;
        this.$refs.mytrack.play();
        
        if (this.$refs.mytrack.readyState < 3) {
            this.loadUntilReady();
        }

        this.$refs.nowplaying.innerText = this.albums[this.album_index].tracks_data[this.track_index].name;
        document.title = this.albums[this.album_index].tracks_data[this.track_index].name + " - " + this.albums[this.album_index].artist;
        this.incrementPlay();
        this.fixFirstPlay();
    },

    lastSong() {
        if (this.track_index - 1 < 0) {
            return;
        }
        this.track_index -= 1;
        this.$refs.mytrack.currentTime=this.albums[this.album_index].tracks_data[this.track_index][this.browser].start;
        this.$refs.mytrack.play();


        if (this.$refs.mytrack.readyState < 3) {
            this.loadUntilReady();
        }

        this.$refs.nowplaying.innerText = this.albums[this.album_index].tracks_data[this.track_index].name;
        document.title = this.albums[this.album_index].tracks_data[this.track_index].name + " - " + this.albums[this.album_index].artist;
        this.incrementPlay();
        this.fixFirstPlay();
    },

    fadePlays() {
        if(this.gotAlbumPlays) {
            this.$refs.playsBox.classList.remove("noShow");
        }
    },

    switchTrack: async function(event) {
        let album_id = parseInt(event.target.id);
        if (this.album_index == -1) {
            this.album_index = 0;
        }
        if(this.is_playing){
            if(this.album_index != album_id){
                this.is_playing = true;
                this.$refs.mytrack.src = this.albums[album_id].tracks[0];
                this.$refs.mytrack.play();
                // this.updateTime = setInterval(this.update,1);
                this.fixFirstPlay();
                this.covers[this.album_index].classList.remove("activeTrack");
                this.covers[this.album_index].style = "";
                this.album_index = album_id;
                this.track_index = 0;
                this.$refs.playbox.classList.remove("noShow");
                this.$refs.info.classList.remove("noShow");
                this.setPlays();
                this.$refs.info.innerHTML = this.albums[this.album_index].name + ' - ' + this.albums[this.album_index].artist;
                setTimeout(this.fadePlays, 1000);
                // setTimeout(function() { this.$refs.info.classList.remove("noShow"); this.$refs.dropdownArrow.classList.remove("noShow"); this.$refs.nowplaying.classList.remove("noShow"); this.$refs.shareLink.classList.remove("noShow"); if(this.gotAlbumPlays) {this.$refs.playsBox.classList.remove("noShow");} }, 1000);
                this.$refs.nowplaying.innerText = this.albums[album_id].tracks_data[0].name;
                document.title = this.albums[this.album_index].tracks_data[this.track_index].name + " - " + this.albums[this.album_index].artist;
                this.covers[this.album_index].classList.add("activeTrack");
                for(let z=0; z<this.covers.length; z++){
                    this.covers[z].style = "";
                }
                this.updateDropdownTracks();
                this.incrementPlay();
            } else {
                this.$refs.mytrack.pause();
                this.is_playing = false;
                // window.clearInterval(this.updateTime);
            }
        } else {
            this.is_playing = true;
            if(this.album_index != album_id){
                let album_id = parseInt(event.target.id);
                this.$refs.mytrack.src = this.albums[album_id].tracks[0];
                this.covers[this.album_index].classList.remove("activeTrack");
                this.covers[this.album_index].style = "";
                this.album_index = album_id;
                this.track_index = 0;
                this.$refs.playbox.classList.remove("noShow");
                this.$refs.info.classList.remove("noShow");
                this.setPlays();
                this.$refs.info.innerHTML = this.albums[this.album_index].name + ' - ' + this.albums[this.album_index].artist;
                setTimeout(this.fadePlays, 1000);
                // setTimeout(function() { this.$refs.info.classList.remove("noShow"); this.$refs.dropdownArrow.classList.remove("noShow"); this.$refs.nowplaying.classList.remove("noShow"); this.$refs.shareLink.classList.remove("noShow"); if(this.gotAlbumPlays) {this.$refs.playsBox.classList.remove("noShow");} }, 1000);
                this.$refs.nowplaying.innerText = this.albums[album_id].tracks_data[0].name;
                document.title = this.albums[this.album_index].tracks_data[this.track_index].name + " - " + this.albums[this.album_index].artist;
                this.covers[this.album_index].classList.add("activeTrack");
                for(let z=0; z<this.covers.length; z++){
                    this.covers[z].style = "";
                }
                this.updateDropdownTracks();
                this.incrementPlay();
            }
            this.$refs.mytrack.play();
            this.fixFirstPlay();
            // this.updateTime = setInterval(this.update,1);
        }
        this.album_index = album_id;
    },

    changeTrack: async function(e) {
        this.track_index = parseInt(e.target.getAttribute("aria-index"));
        this.$refs.mytrack.muted = true;

        this.$refs.nowplaying.innerText = this.albums[this.album_index].tracks_data[this.track_index].name;
        document.title = this.albums[this.album_index].tracks_data[this.track_index].name + " - " + this.albums[this.album_index].artist;
        
        this.$refs.playBtn.classList.add("noDisplay");
        this.$refs.playLoader.classList.remove("noDisplay");

        this.fixingTime = true;
        this.$refs.mytrack.currentTime=this.albums[this.album_index].tracks_data[this.track_index][this.browser].start;
        this.$refs.mytrack.play();
        
        while(this.$refs.mytrack.readyState < 3) {
            await this.sleep(50);
        }

        await this.sleep(1000);
        // if (this.$refs.mytrack.readyState < 3) {
        // 	this.loadUntilReady();
        // }
        this.$refs.mytrack.pause();
        this.fixingTime = false;
        this.$refs.mytrack.muted = false;
        this.$refs.mytrack.currentTime=this.albums[this.album_index].tracks_data[this.track_index][this.browser].start;

        this.$refs.playLoader.classList.add("noDisplay");
        this.$refs.playBtn.classList.remove("noDisplay");

        this.$refs.mytrack.play();

        // this.updateTime = setInterval(this.update,1);
        this.setPlays();
        this.incrementPlay();
    },

    updateDropdownTracks: async function() {
        if (this.album_index == -1) {
            return;
        }

        let prevDropped = this.dropped;

        if (prevDropped) {
            await this.dropdown();
        }

        let track_height = this.vh * 4.6;
        let tracks_full_height = this.vh * 75;
        let tracks_per_page = this.albums[this.album_index].tracks_data.length;

        let fake_tracks_per_page = tracks_per_page;
        if (tracks_per_page <= this.albums[this.album_index].tracks_data.length) {
            fake_tracks_per_page -= 2;
        }

        document.getElementById('tracksCarousel').remove();

        let tmp_tracksCarousel = document.createElement("div");
        tmp_tracksCarousel.id = "tracksCarousel";

        let slider = document.createElement("div");
        slider.classList.add("eclipse-slider");
        
        let t = 0;
        while (t<this.albums[this.album_index].tracks_data.length) {
            let page = document.createElement("div");
            page.classList.add("track_page");

            let ul = document.createElement("ul");
            ul.classList.add("tracks");
            for (let y=0; y<fake_tracks_per_page; y++) {
                if (t>=this.albums[this.album_index].tracks_data.length) {
                    break;
                }
                var div = document.createElement("div");
                div.classList.add("title");
                div.addEventListener("click", this.changeTrack);
                var li = document.createElement("li");
                li.classList.add("track", "noShow", "noDisplay");
                li.appendChild(div);
                
                ul.appendChild(li);
                t++;
            }

            page.appendChild(ul);
            slider.appendChild(page);
        }
        
        tmp_tracksCarousel.appendChild(slider);
        this.$refs.theTracks.appendChild(tmp_tracksCarousel);
        // $('#tracksCarousel').eclipse({friction: 50, speed: 180});

        var listNodes = document.getElementsByClassName("track");
        for (let x=0; x<this.albums[this.album_index].tracks_data.length; x++) {
            let title = listNodes[x].getElementsByClassName("title")[0];
            title.innerText = this.albums[this.album_index].tracks_data[x].name;
            title.setAttribute("aria-index", x + "");

            if (this.gotAlbumPlays) {
                var oldPlays2 = listNodes[x].getElementsByClassName("plays2");
                if (oldPlays2.length == 0) {
                    var pb = document.createElement("div");
                    pb.classList.add("playsBox2");

                    var p = document.createElement("div");
                    p.classList.add("plays2");
                    p.innerHTML = "" + this.getPlays(x);

                    var svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
                    svg.setAttribute("width", "24");
                    svg.setAttribute("height", "24");
                    svg.setAttribute("viewBox", "0 0 24 24");
                    // svg.setAttribute("path", "M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-3 17v-10l9 5.146-9 4.854z");

                    var pth = document.createElementNS('http://www.w3.org/2000/svg' ,"path");
                    pth.setAttribute("d", "M3 22v-20l18 10-18 10z");

                    svg.appendChild(pth);
                    pb.appendChild(p);
                    pb.appendChild(svg);

                    listNodes[x].appendChild(pb);
                } else {
                    oldPlays2[0].innerHTML = "" + this.getPlays(x);
                }
                
            }
        }
        await this.sleep(500);
        if (this.albums[this.album_index].tracks_data.length > tracks_per_page) {
            this.$refs.theTracks.style.height = Math.floor((1.5 + tracks_per_page) * track_height) + 'px';
        } else {
            this.$refs.theTracks.style.height = Math.floor((0.5 + this.albums[this.album_index].tracks_data.length) * track_height) + 'px';
        }
        
        if (prevDropped) {
            this.dropdown();
        }
    },

    devModeFiles(albums) {
      var new_ablums = albums;
      var limit;
      if (!this.devLocal) {
        limit = new_ablums.length;
      }
      else {
        limit = 4;
      }
      for (let x=0; x<limit; x++) {
        if (!this.devLocal) {
          if (new_ablums[x].cover.indexOf("MUSIC/") != -1) {
              new_ablums[x].cover = "https://gproductions.org/" + new_ablums[x].cover.replace("./", "").trim();  
          } else {
              new_ablums[x].cover = "https://gproductions.org/MUSIC/" + new_ablums[x].cover.replace("./", "").trim();  
          }
        } else {
          new_ablums[x].cover = require("@/assets/music/" + new_ablums[x].cover.replace("./", "").trim());
        }
        
        for (let y=0; y<new_ablums[x].tracks.length; y++) {
          if (!this.devLocal) {
            if (new_ablums[x].cover.indexOf("MUSIC/") != -1) {
                new_ablums[x].tracks[y] = "https://gproductions.org/" + new_ablums[x].tracks[y].replace("./", "").trim(); 
            } else {
                new_ablums[x].tracks[y] = "https://gproductions.org/MUSIC/" + new_ablums[x].tracks[y].replace("./", "").trim(); 
            }
          } else {
            if (new_ablums[x].tracks[y].indexOf('.mp3') == -1) {
              new_ablums[x].tracks.splice(y, 1);
              y--;
              continue;
            }
            new_ablums[x].tracks[y] = require("@/assets/music/" + new_ablums[x].tracks[y].replace("./", "").trim()); 
          }
          
        }
      }

      return new_ablums;
    },

    audioApp: async function() {
        var data = await this.getAlbums();

        if (!this.devMode) {
            this.albums = data.albums;
        } else {
            this.albums = this.devModeFiles(data.albums);
        }

        for (let x=0; x<Math.ceil(this.albums.length/6); x++) {
            var i_li = document.createElement("li");
            i_li.setAttribute("data-target", "#myCarousel");
            i_li.setAttribute("data-slide-to", x + "");


            var div = document.createElement("div");
            div.classList.add("item");

            var list = document.createElement("ul");
            list.classList.add("myList");
            var make_active = false;
            for (let y=x * 6; y<6 * (x + 1); y++) {
                let album = this.albums[y];
                if (!album) {
                    break;
                }
                var li = document.createElement("li");
                var img = document.createElement("img");
                img.src = album.cover;
                img.setAttribute("aria-album", album.name);
                img.className = "slideMe noShow";
                img.onload = function(e) {
                    e.target.classList.remove("noShow");
                }
                if (this.q_album && this.q_album == album.name) {
                    make_active = true;
                    this.album_index = y;
                    this.q_album_index = y;
                    if (this.q_track) {
                        for (let t=0; t<album.tracks_data.length; t++) {
                            if (album.tracks_data[t].name.indexOf(this.q_track) != -1) {
                                this.track_index = t;
                                this.q_track_index = t;
                                break;
                            }
                        }
                    }
                }
                // if(begin){
                // 	img.className = "slideMe activeTrack";
                // 	begin = false;
                // }
                img.addEventListener("click", this.switchTrack.bind(this));
                img.id = y + "";
                
                li.appendChild(img);
                list.appendChild(li);
            }

            if (make_active || (x==0 && !this.q_album)) {
                div.classList.add("active");
                i_li.classList.add("active");
            }

            // indicators.appendChild(i_li);
            
            let outer = document.createElement("div");
            outer.classList.add("outer");

            let middle = document.createElement("div");
            middle.classList.add("middle")

            let inner = document.createElement("div");
            inner.classList.add("inner");

            inner.appendChild(list);
            middle.appendChild(inner);
            outer.appendChild(middle);

            div.appendChild(outer);
            this.$refs.carousel.appendChild(div);

        }

        this.num_pages = Math.ceil(this.albums.length/6);

        this.covers = document.getElementsByClassName("slideMe");

        this.$refs.mytrack.addEventListener("ended",function(){
            this.skipSong();
        });

        $('.cv-carousel').carouselVertical({items: 1, nav: false});
        
        
        if (this.q_album) {
            $('.cv-carousel').trigger('goTo', [Math.ceil(this.album_index/6)]);
            this.current_page = Math.ceil(this.album_index/6);

            this.is_playing = false;
            this.$refs.mytrack.src = this.albums[this.album_index].tracks[0];
            this.$refs.mytrack.currentTime = this.albums[this.album_index].tracks_data[this.track_index][this.browser].start;
            // this.updateTime = setInterval(this.update,1);
            this.$refs.playbox.classList.remove("noShow");
            this.$refs.info.classList.remove("noShow");
            this.setPlays();
            this.$refs.info.innerHTML = this.albums[this.album_index].name + ' - ' + this.albums[this.album_index].artist;
            setTimeout(this.fadePlays, 1000);
            // setTimeout(function() { this.$refs.info.classList.remove("noShow"); this.$refs.dropdownArrow.classList.remove("noShow"); this.$refs.nowplaying.classList.remove("noShow"); this.$refs.shareLink.classList.remove("noShow"); if(this.gotAlbumPlays) {this.$refs.playsBox.classList.remove("noShow");} }, 1000);
            this.$refs.nowplaying.innerText = this.albums[this.album_index].tracks_data[this.track_index].name;
            document.title = this.albums[this.album_index].tracks_data[this.track_index].name + " - " + this.albums[this.album_index].artist;
            this.covers[this.album_index].classList.add("activeTrack");
            for(let z=0; z<this.covers.length; z++){
                this.covers[z].style = "";
            }
            this.updateDropdownTracks();
            await this.sleep(500);
            // M.toast({html: 'Click album to play'});
        }

        this.getAllPlays();
    },

    pageScrollBack() {
        this.$refs.nowplaying.scrollBy(-1,0);
        if (this.$refs.nowplaying.scrollLeft != 0) {
            this.scrolldelay2 = setTimeout(this.pageScrollBack,8);
        } else {
            this.$refs.nowplaying.classList.add("ellipsis");
        }
    },

    pageScroll: async function() {
        if (this.scrollMode == 0) {
            this.$refs.nowplaying.scrollBy(1,0);
        } else {
            this.$refs.nowplaying.scrollBy(-1,0);
        }
        if (this.lastScrollVal != this.$refs.nowplaying.scrollLeft) {
            this.scrolldelay = setTimeout(this.pageScroll,15);
        } else {
            await this.sleep(400);
            this.pageScrollBack();
        }
        this.lastScrollVal = this.$refs.nowplaying.scrollLeft;
        
    },

    nowplayingClick() {
        clearTimeout(this.scrolldelay2);
        this.scrollMode = 0;
        this.lastScrollVal = -1;
        this.pageScroll();
        this.$refs.nowplaying.classList.remove("ellipsis");
    },

    dropdown: async function() {
        if (!this.dropped) {
            this.$refs.dropdownArrow.classList.add("drop");
            for (let elem of document.getElementsByClassName("track")) {
                elem.classList.remove("noDisplay");
            }
            
            this.$refs.all_tracks.classList.add("tracksDrop");
            this.$refs.playbox.classList.add("playboxDrop");
            await this.sleep(60);

            for (let elem of document.getElementsByClassName("track")) {
                elem.classList.remove("noShow");
                await this.sleep(40);
            }
            this.dropped = true;
        } else {
            this.$refs.all_tracks.scrollTo(0, 0);

            var listNodes = document.getElementsByClassName("track");
            var arrayNodes = Array.from(listNodes);
            arrayNodes.reverse();

            for (let elem of arrayNodes) {
                elem.classList.add("noShow");
                await this.sleep(40);
            }
            await this.sleep(10);
            this.$refs.all_tracks.classList.remove("tracksDrop");

            for (let elem of arrayNodes) {
                elem.classList.add("noDisplay");
                await this.sleep(10);
            }
            
            this.$refs.dropdownArrow.classList.remove("drop");
            this.$refs.playbox.classList.remove("playboxDrop");
            this.dropped = false;
        }
    },

    copyToClipboard(str) {
        const el = document.createElement('textarea');
        el.value = str;
        el.setAttribute('readonly', '');
        el.style.position = 'absolute';
        el.style.left = '-9999px';
        document.body.appendChild(el);
        el.select();
        el.setSelectionRange(0, 99999);
        document.execCommand('copy');
        document.body.removeChild(el);
    },

    shareSong() {
        if (this.album_index == -1) {
            return;
        }
        let url = new URL('https://gproductions.org/MUSIC?album=' + this.albums[this.album_index].name + "&track=" + this.albums[this.album_index].tracks_data[this.track_index].name).href;
        if (navigator.share) {
            let title;
            if (this.albums[this.album_index].tracks_data.length > 1) {
                title = this.albums[this.album_index].tracks_data[this.track_index].name + " - " + this.albums[this.album_index].artist + " | " + this.albums[this.album_index].name;
            } else {
                title = this.albums[this.album_index].name + " - " + this.albums[this.album_index].artist;
            }
            navigator.share({
                title: title,
                url: url,
                text: title
            })
            .then(() => console.log('Successful share'))
            .catch((error) => console.log('Error sharing', error));
        } else {
            this.copyToClipboard(url);
            // M.toast({html: 'Link copied to clipboard'})
        }
    },

    setPlays() {
        this.$refs.playsElem.innerHTML = this.getPlays();
    },

    getAllPlays: async function() {
        fetch("https://music.gproductions.org/API/plays", {method: 'get', headers: { "Content-Type": "application/json" }})
        .then((res) => res.json())
        .then((data) => {
            this.album_plays = data;
            this.gotAlbumPlays = true;
            if (this.album_index != -1) {
                this.setPlays();
                this.$refs.playsBox.classList.remove("noShow");
                this.updateDropdownTracks();
            }
            
        })
    },

    getPlays(t_input) {
        let the_track;
        if (typeof t_input !== 'undefined') {
            the_track = t_input;
        } else {
            the_track = this.track_index;
        }

        if (this.album_index != -1 && this.gotAlbumPlays && this.album_plays[this.albums[this.album_index].name + "~" + this.albums[this.album_index].artist] && this.album_plays[this.albums[this.album_index].name + "~" + this.albums[this.album_index].artist][this.albums[this.album_index].tracks_data[the_track].name]) {
            return this.album_plays[this.albums[this.album_index].name + "~" + this.albums[this.album_index].artist][this.albums[this.album_index].tracks_data[the_track].name];
        } else {
            return 0;
        }
    },

    incrementPlay: async function() {
        let p_album_index = this.album_index;
        let p_track_index = this.track_index;

        if (localStorage.getItem('G_Data_Music')) {
            let json_listens = JSON.parse(localStorage.getItem('G_Data_Music'));
            if (json_listens[this.albums[p_album_index].name + "~" + this.albums[p_album_index].artist] && json_listens[this.albums[p_album_index].name + "~" + this.albums[p_album_index].artist][this.albums[p_album_index].tracks_data[p_track_index].name]) {
                this.setPlays();
                return;
            }
        }

        let data = {
            album: this.albums[p_album_index].name,
            artist: this.albums[p_album_index].artist,
            track: this.albums[p_album_index].tracks_data[p_track_index].name
        }
        fetch("https://music.gproductions.org/API/plays", {method: 'post', headers: { "Content-Type": "application/json" }, body: JSON.stringify(data)})
        .then((res) => {
            if (localStorage.getItem('G_Data_Music')) {
                let json_listens = JSON.parse(localStorage.getItem('G_Data_Music'));
                if (!json_listens[this.albums[p_album_index].name + "~" + this.albums[p_album_index].artist]) {
                    json_listens[this.albums[p_album_index].name + "~" + this.albums[p_album_index].artist] = {};
                }

                if (!json_listens[this.albums[p_album_index].name + "~" + this.albums[p_album_index].artist][this.albums[p_album_index].tracks_data[p_track_index].name]) {
                    json_listens[this.albums[p_album_index].name + "~" + this.albums[p_album_index].artist][this.albums[p_album_index].tracks_data[p_track_index].name] = true;
                }

                localStorage.setItem('G_Data_Music', JSON.stringify(json_listens));

            } else {
                let json_listens = {};
                json_listens[this.albums[p_album_index].name + "~" + this.albums[p_album_index].artist] = {};
                json_listens[this.albums[p_album_index].name + "~" + this.albums[p_album_index].artist][this.albums[p_album_index].tracks_data[p_track_index].name] = true;
                localStorage.setItem('G_Data_Music', JSON.stringify(json_listens));
            }

            if (!this.album_plays[this.albums[p_album_index].name + "~" + this.albums[p_album_index].artist]) {
                this.album_plays[this.albums[p_album_index].name + "~" + this.albums[p_album_index].artist] = {};
            }
            if (!this.album_plays[this.albums[p_album_index].name + "~" + this.albums[p_album_index].artist][this.albums[p_album_index].tracks_data[p_track_index].name]) {
                this.album_plays[this.albums[p_album_index].name + "~" + this.albums[p_album_index].artist][this.albums[p_album_index].tracks_data[p_track_index].name] = 1;
            } else {
                this.album_plays[this.albums[p_album_index].name + "~" + this.albums[p_album_index].artist][this.albums[p_album_index].tracks_data[p_track_index].name] += 1;
            }
            
            if (p_album_index == this.album_index) {
                document.getElementsByClassName("plays2")[p_track_index].innerHTML = this.album_plays[this.albums[p_album_index].name + "~" + this.albums[p_album_index].artist][this.albums[p_album_index].tracks_data[p_track_index].name];
                if (p_track_index == this.track_index) {
                    this.setPlays();
                }
            }
        })
    },

    setCurrentPage() {
        let pages = document.getElementsByClassName("cv-item");
        for (let x=0; x<pages.length; x++) {
            if (pages[x].classList.contains("active")) {
                this.current_page = x + 1;
            }
        }
    },

    changePage(diff) {
        this.setCurrentPage();
        let tmp_page = this.current_page + diff;
        if (tmp_page < 1 || tmp_page > this.num_pages) {
            return;
        }

        $('.cv-carousel').trigger('goTo', [tmp_page]);
        this.current_page = tmp_page;
    },

    mytrackPause() {
        if (this.fixingTime) {
            return;
        }

        window.clearInterval(this.updateTime);

        var svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
        svg.setAttribute("width", "24");
        svg.setAttribute("height", "24");
        svg.setAttribute("viewBox", "0 0 24 24");
        // svg.setAttribute("path", "M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-3 17v-10l9 5.146-9 4.854z");

        var pth = document.createElementNS('http://www.w3.org/2000/svg' ,"path");
        pth.setAttribute("d", "M3 22v-20l18 10-18 10z");

        svg.appendChild(pth);
        this.$refs.playBtn.getElementsByTagName("svg")[0].remove();
        this.$refs.playBtn.appendChild(svg);
    },

    mytrackPlay() {
        if (this.fixingTime) {
            return;
        }

        this.updateTime = setInterval(this.update.bind(this),1);

        if (this.$refs.mytrack.readyState < 3) {
            this.loadUntilReady();
        } else {
            this.load_is_playing = true;
        }

        var svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
        svg.setAttribute("width", "24");
        svg.setAttribute("height", "24");
        svg.setAttribute("viewBox", "0 0 24 24");
        // svg.setAttribute("path", "M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-3 17v-10l9 5.146-9 4.854z");

        var pth = document.createElementNS('http://www.w3.org/2000/svg' ,"path");
        pth.setAttribute("d", "M11 22h-4v-20h4v20zm6-20h-4v20h4v-20z");

        svg.appendChild(pth);
        this.$refs.playBtn.getElementsByTagName("svg")[0].remove();
        this.$refs.playBtn.appendChild(svg);
    },

    playBtnClick() {
        if (this.$refs.mytrack.paused) {
            this.$refs.mytrack.play();
            this.fixFirstPlay();
        } else {
            this.$refs.mytrack.pause();
        }
    },

    loadUntilReady: async function() {
        this.load_is_playing = false;
        this.$refs.playBtn.classList.add("noDisplay");
        this.$refs.playLoader.classList.remove("noDisplay");

        while(this.$refs.mytrack.readyState < 3) {
            await this.sleep(50);
        }

        let lastTime = this.$refs.mytrack.currentTime;
        while(this.$refs.mytrack.currentTime == lastTime) {
            await this.sleep(50);
        }

        this.load_is_playing = true;

        this.$refs.playLoader.classList.add("noDisplay");
        this.$refs.playBtn.classList.remove("noDisplay");

    },

    fixFirstPlay: async function() {
        if (this.browser == 'safari' && !this.first_click && this.q_album && this.album_index == this.q_album_index && this.q_track_index == this.track_index) {
            this.first_click = true;
            this.$refs.mytrack.muted = true;
            while(!this.load_is_playing) {
                await this.sleep(50);
            }
            this.$refs.mytrack.pause();
            this.$refs.mytrack.currentTime = this.albums[this.album_index].tracks_data[this.track_index][this.browser].start;
            this.$refs.mytrack.muted = false;
            this.$refs.mytrack.play();
            this.loadUntilReady();
        } else {
            this.first_click = true;
        }
    }

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import '../assets/music/eclipse/eclipse.css';
@import '../assets/music/carousel/carousel-vertical.css';

.body {
  /* background: white; */
  height: 100vh;
  position: relative;
  background-color: #e7e7e7;
  padding: 0;
  padding-top: 4rem;
}




svg{
    margin: auto; width: inherit; height: inherit;
    transition: 0.1s;
}

svg:active {
    transform: scale(1.3);
}

.outer {
	display: table;
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
  }
  
  .middle {
	display: table-cell;
	vertical-align: middle;
  }
  
  .inner {
	margin-left: auto;
	margin-right: auto;
	/* width: fit-content; */
	position: relative;
	/*whatever width you want*/
  }


.spinner {
    width: 40px;
    height: 10px;
    text-align: center;
    font-size: 10px;
}
  
  .spinner > div {
    background-color: #999;
    height: 100%;
    width: 6px;
    display: inline-block;
    
    -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
    animation: sk-stretchdelay 1.2s infinite ease-in-out;
  }
  
  .spinner .rect2 {
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s;
  }
  
  .spinner .rect3 {
    -webkit-animation-delay: -1.0s;
    animation-delay: -1.0s;
  }
  
  .spinner .rect4 {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
  }
  
  .spinner .rect5 {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
  }
  
  @-webkit-keyframes sk-stretchdelay {
    0%, 40%, 100% { -webkit-transform: scaleY(0.4) }  
    20% { -webkit-transform: scaleY(1.0) }
  }
  
  @keyframes sk-stretchdelay {
    0%, 40%, 100% { 
      transform: scaleY(0.4);
      -webkit-transform: scaleY(0.4);
    }  20% { 
      transform: scaleY(1.0);
      -webkit-transform: scaleY(1.0);
    }
  }


</style>