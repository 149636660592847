<template>
  <div class="body">
    <div id="view" :class="{'gScroll': isWindows, 'music_view': currentPage == 3, 'lf_view': currentPage == 4, 'photography_view': currentPage == 1, 'film_view': currentPage == 2}">
      <home v-if="currentPage == 0" :pageMessages="pageMessages" :thisPage="0" :isMobile="false" :isWindows="isWindows"/>
      <photography v-if="currentPage == 1" :pageMessages="pageMessages" :thisPage="1" :isMobile="false"/>
      <film v-if="currentPage == 2" :pageMessages="pageMessages" :thisPage="2"/>
      <music v-if="currentPage == 3" :pageMessages="pageMessages" :thisPage="3" :isMobile="false"/>
      <LF v-if="currentPage == 4" :pageMessages="pageMessages" :thisPage="4" :isMobile="false"/>
    </div>

    <div id="navbarLP" class="navbarShadow" :class="{'navbarNormal': currentPage == 3, 'navbarFixed': currentPage != 0}">
      <ul>
        <li :class="{'activeTab': currentPage == 0}" @click="changeView($event, 0)"><a href="https://gproductions.org/" @click="prevent($event)">HOME</a></li>
        <li :class="{'activeTab': currentPage == 1}" @click="changeView($event, 1)"><a href="https://gproductions.org/PHOTOGRAPHY" @click="prevent($event)">PHOTOGRAPHY</a></li>
        <li :class="{'activeTab': currentPage == 2}" @click="changeView($event, 2)"><a href="https://gproductions.org/FILM" @click="prevent($event)">FILM</a></li>
        <li :class="{'activeTab': currentPage == 3}" @click="changeView($event, 3)"><a href="https://gproductions.org/MUSIC" @click="prevent($event)">MUSIC</a></li>
        <li :class="{'activeTab': currentPage == 4}" @click="changeView($event, 4)"><a href="https://gproductions.org/LF" @click="prevent($event)">LF</a></li>
        <!-- <li :class="{'activeTab': currentPage == 4}"><a href="https://gproductions.org/LF" @click="changeView($event, 4)">BENNY'S</a></li> -->
      </ul>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */

import $ from 'jquery'
import smoothscroll from 'smoothscroll-polyfill';

import Home from './Home.vue'
import Film from './Film.vue'
import LF from './LF.vue'
import Music from './Music.vue'
import Photography from './Photography.vue'

export default {
  name: 'HOME',
  props: {
    browser: String,
    isWindows: Boolean
  },

  components: {
    Home,
    Film,
    LF,
    Music,
    Photography
  },

  data: function() {
    return {
      currentPage: 0,
      pageMessages: {
        0: ["to_about", "left_about"],
        1: ["to_photography", "left_photography"],
        2: ["to_film", "left_film"],
        3: ["to_music", "left_music"],
        4: ["to_lf", "left_lf"], 
      },

      pageMap: {
        0: "",
        1: "PHOTOGRAPHY",
        2: "FILM",
        3: "MUSIC",
        4: "LF"
      },

      pathMap: {
        "": 0,
        "PHOTOGRAPHY": 1,
        "FILM": 2,
        "MUSIC": 3,
        "LF": 4
      }
    }
  },

  created() {
    let path = window.location.pathname.substring(1);
    if (path.indexOf('?') != -1) {
      path = path.substring(0, path.indexOf('?'));
    }
    if (path.indexOf('/') != -1) {
      path = path.substring(0, path.indexOf('/'));
    }
    this.currentPage = this.pathMap[path];
  },
  
  mounted() {
    this.$root.$emit(this.pageMessages[this.currentPage][0]);
  },
  methods: {
    prevent(e) {
      e.preventDefault();
    },

    sleep(milliseconds) {
      return new Promise(function(resolve, reject) {
        setTimeout(resolve, milliseconds, true);
      });
    },

    changeView: async function(e, new_view) {
      e.preventDefault();
      this.$root.$emit(this.pageMessages[this.currentPage][1]);
      await this.sleep(700);
      this.currentPage = new_view;
      this.$root.$emit(this.pageMessages[this.currentPage][0]);
      window.history.pushState({}, this.pageMap[this.currentPage], "/" + this.pageMap[this.currentPage]);
      return false;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.body {
  /* background: white; */
  /* position: absolute; */
}

.navbarShadow {
  box-shadow: 0px -1px 4px rgba(0,0,0,1);
}

#navbarLP {
  position: fixed;
  width: 100vw;
  height: 4vh;
  bottom: -4vh;
  /* color: rgb(192, 192, 192); */
  font-family: helvetica;
  letter-spacing: 3px;
  font-size: 0.5vw;
  background: black;
  display: inline-grid;
  /* -webkit-font-smoothing: antialiased; */
  /* font-weight: bold; */
  z-index: 10000;
  /* opacity: 0; */
}

#navbarLP ul {
  list-style: none;
  display: flex;
  /* margin-bottom: 0 !important; */
  padding-inline-start: 0;
  padding-right: 0rem;
  padding-left: 0rem;
  width: 90%;
  margin: auto;
}

#navbarLP li {
  margin: auto;
  padding: 1rem;
  text-align: center;
  vertical-align: middle;
  transition: 0.6s;
  cursor: pointer;
  color: rgb(160, 160, 160);
}

#navbarLP li:hover {
  color: rgba(255,255,255,0.9);
}

#navbarLP a {
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
}

.activeTab {
  color: white !important;
  transition: 0.2s;
}

.navbarFixed {
  /* position: fixed !important; */
  /* top: 0 !important; */
  /* box-shadow: 0px 1px 4px rgba(50,50,50,1) !important; */
  /* box-shadow: none !important; */
  bottom: 0 !important;
}

.navbarNormal {
  position: relative !important; 
}

a {
  color: inherit;
  transition: 0.1s;
  text-decoration: none !important;
}

a:hover {
  color: inherit;
  text-decoration: none;
}

a:focus {
  color: inherit;
  outline: none;
}

#view {
  height: 100vh;
  width: 100vw;
  overflow-y: scroll;
  transition: 0.5s;
}

.music_view {
  background: #E7E7E7;
  overflow-y: hidden !important;
}

.lf_view {
  background: white;
}

.photography_view {
  overflow-y: hidden !important;
}

.film_view {
  overflow-y: hidden !important;
}


@media (min-width: 2000px) {
  #navbarLP {
    font-size: 0.4vw;
  }

}
</style>
