<template>
  <div ref="music_body" class="body noShow">
    <div id="trackbox" style="height: 100%;">
      <div id="playbox" ref="playbox" class="">
        <div id="playstuff">
          <div id="dropdown" ref="dropdownArrow" class="noShow" @click="dropdown()"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z"/></svg></div>
          <div id="nowplaying" ref="nowplaying" class="noShow"></div>
          <div id="shareLink" ref="shareLink" class="noShow" @click="shareSong()"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-share"><path d="M4 12v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-8"/><polyline points="16 6 12 2 8 6"/><line x1="12" y1="2" x2="12" y2="15"/></svg></div>
          <div id="playsBox" ref="playsBox" class="noShow"><div id="plays" ref="playsElem"></div><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-3 17v-10l9 5.146-9 4.854z"/></svg></div>
        </div>
        <div id="all_tracks" class="noHeight">
          <ul id="tracks" ref="tracksList"></ul>
        </div>
      </div>

      <div id="info_out"><div id="info" ref="info" class="noShow">-</div></div>

      <div class="outer">
        <div class="middle">
          <div class="inner">
            <div id="myCarousel" class="carousel slide">
              <div id="c-inner" ref="carousel_inner" class="carousel-inner"></div>
            </div>
          </div>
        </div>
      </div>
      
      <ol id="c-indicators" ref="indicators" class="carousel-indicators"></ol>
    </div>
        
    <div id="wrapper" class="hideWrapper">
      <audio id="mytrack" ref="mytrack" ></audio>

      <nav id="soundBar">
        <div id="skipBackward" ref="skipBackward">
            <div id="hoverBack"></div>
        </div>   

        <div id="defaultBar" ref="bar">
          <div id="progressBar" ref="progressBar"> </div>
          <!-- <div id="currentTimeDot" ref="currentDot"> </div> -->
        </div>

        <div id="skipForward" ref="skipForward">
            <div id ="hoverSkip"></div>
        </div>
      </nav>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */

import $ from 'jquery'
import M from 'materialize-css'

export default {
  name: 'MUSIC',
  props: {
    pageMessages: Object,
    thisPage: Number,
    isMobile: Boolean
  },

  data: function() {
    return {
      albums: [],
      album_index: -1,
      track_index: 0,
      updateTime: null,
      album_plays: {},
      is_playing: false,
      first_click: false,
      gotAlbumPlays: false,
      dropped: false,
      lastScrollVal: -1,
      scrollMode: 0,
      scrolldelay: null,
      scrolldelay2: null,
      covers: [],
      devMode: false,
      devLocal: false,
      
    }
  },

  computed: {
    barSize: function () {
      return window.innerWidth*0.88;
    },

    initialDot: function() {
      return window.innerWidth*-1;
    }
  },

  created() {

  },

  mounted: async function() {
    this.$root.$on(this.pageMessages[this.thisPage][0], this.scrolledTo);
    this.$root.$on(this.pageMessages[this.thisPage][1], this.scrolledAway);

    this.$refs.mytrack.addEventListener("loadedmetadata",this.changeDuration);
    this.$refs.bar.addEventListener('click',this.clickedBar,false);
    this.$refs.bar.addEventListener('mouseover',this.changedBSize,false);
    this.$refs.bar.addEventListener('mouseleave',this.resetSize,false);
    window.addEventListener('keydown', this.keyboardAction, false);
    this.$refs.skipForward.addEventListener('click',this.skipSong,false);
    this.$refs.skipBackward.addEventListener('dblclick',this.lastSong,false);
    this.$refs.skipBackward.addEventListener('click',this.restartSong,false);
    this.audioApp();
    this.$refs.nowplaying.onmouseover = this.nowPlayingScroll;
    this.$refs.nowplaying.onmouseout = this.nowPlayingStopScroll;
    await this.sleep(300);
    this.covers = document.getElementsByClassName("slideMe");
    this.$refs.music_body.classList.remove("noShow");
    this.scrolledTo();
  },
  methods: {
    sleep(milliseconds) {
      return new Promise(function(resolve, reject) {
        setTimeout(resolve, milliseconds, true);
      });
    },

    copyToClipboard(str) {
      const el = document.createElement('textarea');
      el.value = str;
      el.setAttribute('readonly', '');
      el.style.position = 'absolute';
      el.style.left = '-9999px';
      document.body.appendChild(el);
      el.select();
      el.setSelectionRange(0, 99999);
      document.execCommand('copy');
      document.body.removeChild(el);
    },

    shareSong() {
      let url = new URL('https://gproductions.org/MUSIC?album=' + this.albums[this.album_index].name + "&track=" + this.trackName(this.albums[this.album_index].tracks[this.track_index])).href;
      if (navigator.share) {
        let title;
        if (this.albums[this.album_index].tracks.length > 1) {
          title = this.trackName(this.albums[this.album_index].tracks[this.track_index]) + " - " + this.albums[this.album_index].artist + " | " + this.albums[this.album_index].name;
        } else {
          title = this.albums[this.album_index].name + " - " + this.albums[this.album_index].artist;
        }
        navigator.share({
          title: title,
          url: url
        })
        .then(() => console.log('Successful share'))
        .catch((error) => console.log('Error sharing', error));
      } else {
        this.copyToClipboard(url);
        M.toast({html: 'Link copied to clipboard'})
      }
    },

    dropdown: async function() {
      if (!this.dropped) {
        this.$refs.dropdownArrow.classList.add("drop");
        for (let elem of document.getElementsByClassName("track")) {
          elem.classList.remove("noDisplay");
        }

        this.$refs.playbox.classList.add("playboxDrop");
        document.getElementById('all_tracks').classList.remove('noHeight');
        await this.sleep(60);

        for (let elem of document.getElementsByClassName("track")) {
          elem.classList.remove("noShow");
          await this.sleep(40);
        }
        this.dropped = true;
      } else {
        this.$refs.tracksList.scrollTo(0, 0);

        var listNodes = document.getElementsByClassName("track");
        var arrayNodes = Array.from(listNodes);
        arrayNodes.reverse();

        for (let elem of arrayNodes) {
          elem.classList.add("noShow");
          await this.sleep(40);
        }
        await this.sleep(10);

        for (let elem of arrayNodes) {
          elem.classList.add("noDisplay");
          await this.sleep(10);
        }
        
        this.$refs.dropdownArrow.classList.remove("drop");
        this.$refs.playbox.classList.remove("playboxDrop");
        document.getElementById('all_tracks').classList.add('noHeight');
        this.dropped = false;
      }
    },

    pageScroll() {
      if (this.scrollMode == 0) {
        this.$refs.nowplaying.scrollBy(1,0);
      } else {
        this.$refs.nowplaying.scrollBy(-1,0);
      }
      if (this.lastScrollVal != this.$refs.nowplaying.scrollLeft) {
        this.scrolldelay = setTimeout(this.pageScroll,20);
      }
      this.lastScrollVal = this.$refs.nowplaying.scrollLeft;
        
    },

    pageScrollBack() {
      this.$refs.nowplaying.scrollBy(-1,0);
      if (this.$refs.nowplaying.scrollLeft != 0) {
        this.scrolldelay2 = setTimeout(this.pageScrollBack,20);
      } else {
        this.$refs.nowplaying.classList.add("ellipsis");
      }
    },

    nowPlayingStopScroll() {
      clearTimeout(this.scrolldelay);
      this.pageScrollBack();
    },

    nowPlayingScroll() {
      clearTimeout(this.scrolldelay2);
      this.scrollMode = 0;
      this.lastScrollVal = -1;
      this.pageScroll();
      this.$refs.nowplaying.classList.remove("ellipsis");
    },

    scrolledTo: async function() {
      await this.sleep(800);
      document.getElementById('wrapper').classList.remove("hideWrapper");
    },
    scrolledAway: async function() {
      document.getElementById('wrapper').classList.add("hideWrapper");
      this.$refs.music_body.classList.add("noShow");
    },

    clickedBar(e){
      if(!this.$refs.mytrack.ended){
        var mouseX = e.pageX - this.$refs.bar.offsetLeft;
        var newtime = mouseX*this.$refs.mytrack.duration/this.barSize;
        this.$refs.mytrack.currentTime = newtime;
        this.$refs.progressBar.style.width = mouseX + 'px';
      }
      
    },

    update() {
      var mytrack = document.getElementById('mytrack');
      if(!mytrack.ended){
        var size = parseInt(mytrack.currentTime*this.barSize/mytrack.duration);
        this.$refs.progressBar.style.width = size + "px";
      }
      else{
        this.$refs.progressBar.style.width = "0px";
        window.clearInterval(this.updateTime);
      }
    },

    trackName(track) {
      return track.substring(track.lastIndexOf('/')+1, track.lastIndexOf('.'));
    },

    incrementPlay: async function() {
      let p_album_index = this.album_index;
      let p_track_index = this.track_index;

      if (localStorage.getItem('G_Data_Music')) {
        let json_listens = JSON.parse(localStorage.getItem('G_Data_Music'));
        if (json_listens[this.albums[p_album_index].name + "~" + this.albums[p_album_index].artist] && json_listens[this.albums[p_album_index].name + "~" + this.albums[p_album_index].artist][this.trackName(this.albums[p_album_index].tracks[p_track_index])]) {
          return;
        }
      }

      let data = {
        album: this.albums[p_album_index].name,
        artist: this.albums[p_album_index].artist,
        track: this.trackName(this.albums[p_album_index].tracks[p_track_index])
      }
      fetch("https://music.gproductions.org/API/plays", {method: 'post', headers: { "Content-Type": "application/json" }, body: JSON.stringify(data)})
      .then((res) => {
        if (localStorage.getItem('G_Data_Music')) {
          let json_listens = JSON.parse(localStorage.getItem('G_Data_Music'));
          if (!json_listens[this.albums[p_album_index].name + "~" + this.albums[p_album_index].artist]) {
            json_listens[this.albums[p_album_index].name + "~" + this.albums[p_album_index].artist] = {};
          }

          if (!json_listens[this.albums[p_album_index].name + "~" + this.albums[p_album_index].artist][this.trackName(this.albums[p_album_index].tracks[p_track_index])]) {
            json_listens[this.albums[p_album_index].name + "~" + this.albums[p_album_index].artist][this.trackName(this.albums[p_album_index].tracks[p_track_index])] = true;
          }

          localStorage.setItem('G_Data_Music', JSON.stringify(json_listens));

        } else {
          let json_listens = {};
          json_listens[this.albums[p_album_index].name + "~" + this.albums[p_album_index].artist] = {};
          json_listens[this.albums[p_album_index].name + "~" + this.albums[p_album_index].artist][this.trackName(this.albums[p_album_index].tracks[p_track_index])] = true;
          localStorage.setItem('G_Data_Music', JSON.stringify(json_listens));
        }

        if (!this.album_plays[this.albums[p_album_index].name + "~" + this.albums[p_album_index].artist]) {
          this.album_plays[this.albums[p_album_index].name + "~" + this.albums[p_album_index].artist] = {};
        }
        if (!this.album_plays[this.albums[p_album_index].name + "~" + this.albums[p_album_index].artist][this.trackName(this.albums[p_album_index].tracks[p_track_index])]) {
          this.album_plays[this.albums[p_album_index].name + "~" + this.albums[p_album_index].artist][this.trackName(this.albums[p_album_index].tracks[p_track_index])] = 1;
        } else {
          this.album_plays[this.albums[p_album_index].name + "~" + this.albums[p_album_index].artist][this.trackName(this.albums[p_album_index].tracks[p_track_index])] += 1;
        }
        
        if (p_album_index == this.album_index) {
          document.getElementsByClassName("plays2")[p_track_index].innerHTML = this.album_plays[this.albums[p_album_index].name + "~" + this.albums[p_album_index].artist][this.trackName(this.albums[p_album_index].tracks[p_track_index])];
          if (p_track_index == this.track_index) {
            this.setPlays();
          }
        }
      })
    },

    restartSong(){
      this.$refs.mytrack.currentTime=0;
    },

    lastSong() {
      if (this.track_index - 1 < 0) {
        return;
      }
      this.track_index -= 1;
      this.$refs.mytrack.src=this.albums[this.album_index].tracks[this.track_index];
      this.$refs.mytrack.play();

      this.updateTime = setInterval(this.update,1);
      this.$refs.nowplaying.innerText = this.albums[this.album_index].tracks[this.track_index].substring(this.albums[this.album_index].tracks[this.track_index].lastIndexOf('/')+1);
      this.incrementPlay();
    },

    skipSong(){
      if (this.track_index + 1 >= this.albums[this.album_index].tracks.length) {
        return;
      }
      this.track_index += 1;
      this.$refs.mytrack.src= this.albums[this.album_index].tracks[this.track_index];
      this.$refs.mytrack.play();
      
      this.updateTime = setInterval(this.update,1);
      this.$refs.nowplaying.innerText = this.albums[this.album_index].tracks[this.track_index].substring(this.albums[this.album_index].tracks[this.track_index].lastIndexOf('/')+1);
      this.incrementPlay();
    },

    getAlbums() {
      return new Promise(function(resolve,reject) {
        fetch("https://music.gproductions.org/API/getAlbums", {method: 'get', headers: { "Content-Type": "application/json" }})
        .then((res) => res.json())
        .then((data) => {
          resolve(data);
        })
      })
    },

    getPlays(t_input) {
      let the_track;
      if (typeof t_input !== 'undefined') {
        the_track = t_input;
      } else {
        the_track = this.track_index;
      }

      if (this.album_index != -1 && this.gotAlbumPlays && this.album_plays[this.albums[this.album_index].name + "~" + this.albums[this.album_index].artist] && this.album_plays[this.albums[this.album_index].name + "~" + this.albums[this.album_index].artist][this.trackName(this.albums[this.album_index].tracks[the_track])]) {
        return this.album_plays[this.albums[this.album_index].name + "~" + this.albums[this.album_index].artist][this.trackName(this.albums[this.album_index].tracks[the_track])];
      } else {
        return 0;
      }
    },

    setPlays() {
      this.$refs.playsElem.innerHTML = this.getPlays();
    },

    playboxFadeIn() {
      this.$refs.info.classList.remove("noShow"); 
      this.$refs.dropdownArrow.classList.remove("noShow"); 
      this.$refs.nowplaying.classList.remove("noShow"); 
      this.$refs.shareLink.classList.remove("noShow"); 
      if(this.gotAlbumPlays) {
        this.$refs.playsBox.classList.remove("noShow");
      }
    },

    changeTrack(e) {
      this.track_index = parseInt(e.target.getAttribute("aria-index"));
      this.$refs.mytrack.src=this.albums[this.album_index].tracks[this.track_index];
      this.$refs.mytrack.play();
      this.updateTime = setInterval(this.update,1);
      this.$refs.nowplaying.innerText = this.albums[this.album_index].tracks[this.track_index].substring(this.albums[this.album_index].tracks[this.track_index].lastIndexOf('/')+1);
      this.setPlays();
      this.incrementPlay();
    },

    updateDropdownTracks: async function() {
      if (this.album_index == -1) {
        return;
      }

      var diff;

      if (this.dropped) {
        this.$refs.tracksList.scrollTo(0, 0);
        var listNodes = document.getElementsByClassName("track");
        var arrayNodes = Array.from(listNodes);
        arrayNodes.reverse();

        for (let elem of arrayNodes) {
          elem.classList.add("noShow");
          await this.sleep(40);
        }
        await this.sleep(10);

        listNodes = document.getElementsByClassName("track");
        diff = this.albums[this.album_index].tracks.length - listNodes.length;
        while(diff != 0) {
          if (diff < 0) {
            listNodes[0].remove();
            diff += 1;
            continue;
          }

          if (diff > 0) {
            var div = document.createElement("div");
            div.classList.add("title");
            div.addEventListener("click", this.changeTrack);
            var li = document.createElement("li");
            li.classList.add("track", "noShow");
            li.appendChild(div);

            this.$refs.tracksList.appendChild(li);
            diff -= 1;
            continue;
          }
        }

        listNodes = document.getElementsByClassName("track");
        for (let x=0; x<this.albums[this.album_index].tracks.length; x++) {
          let title = listNodes[x].getElementsByClassName("title")[0];
          title.innerText = this.albums[this.album_index].tracks[x].substring(this.albums[this.album_index].tracks[x].lastIndexOf('/')+1);
          title.setAttribute("aria-index", x + "");

          if (this.gotAlbumPlays) {
            var oldPlays2 = listNodes[x].getElementsByClassName("plays2");
            if (oldPlays2.length == 0) {
              var pb = document.createElement("div");
              pb.classList.add("playsBox2");

              var p = document.createElement("div");
              p.classList.add("plays2");
              p.innerHTML = "" + this.getPlays(x);

              var svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
              svg.setAttribute("width", "24");
              svg.setAttribute("height", "24");
              svg.setAttribute("viewBox", "0 0 24 24");
              // svg.setAttribute("path", "M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-3 17v-10l9 5.146-9 4.854z");

              var pth = document.createElementNS('http://www.w3.org/2000/svg' ,"path");
              pth.setAttribute("d", "M3 22v-20l18 10-18 10z");

              svg.appendChild(pth);
              pb.appendChild(p);
              pb.appendChild(svg);

              listNodes[x].appendChild(pb);
            } else {
              oldPlays2[0].innerHTML = "" + this.getPlays(x);
            }
            
          }

          listNodes[x].classList.remove("noShow");
          await this.sleep(30);
        }
      } else {
        listNodes = document.getElementsByClassName("track");
        diff = this.albums[this.album_index].tracks.length - listNodes.length;
        while(diff != 0) {
          if (diff < 0) {
            listNodes[0].remove();
            diff += 1;
            continue;
          }

          if (diff > 0) {
            div = document.createElement("div");
            div.classList.add("title");
            div.addEventListener("click", this.changeTrack);
            li = document.createElement("li");
            li.classList.add("track", "noShow", "noDisplay");
            li.appendChild(div);
            
            this.$refs.tracksList.appendChild(li);
            diff -= 1;
            continue;
          }
        }

        listNodes = document.getElementsByClassName("track");
        for (let x=0; x<this.albums[this.album_index].tracks.length; x++) {
          let title = listNodes[x].getElementsByClassName("title")[0];
          title.innerText = this.albums[this.album_index].tracks[x].substring(this.albums[this.album_index].tracks[x].lastIndexOf('/')+1);
          title.setAttribute("aria-index", x + "");

          if (this.gotAlbumPlays) {
            oldPlays2 = listNodes[x].getElementsByClassName("plays2");
            if (oldPlays2.length == 0) {
              pb = document.createElement("div");
              pb.classList.add("playsBox2");

              p = document.createElement("div");
              p.classList.add("plays2");
              p.innerHTML = "" + this.getPlays(x);

              svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
              svg.setAttribute("width", "24");
              svg.setAttribute("height", "24");
              svg.setAttribute("viewBox", "0 0 24 24");          

              pth = document.createElementNS('http://www.w3.org/2000/svg' ,"path");
              pth.setAttribute("d", "M3 22v-20l18 10-18 10z");

              svg.appendChild(pth);
              pb.appendChild(p);
              pb.appendChild(svg);

              listNodes[x].appendChild(pb);
            } else {
              oldPlays2[0].innerHTML = "" + this.getPlays(x);
            }
            
          }
        }
      }
      
    },

    switchTrack: async function(event){
      let album_id = parseInt(event.target.id);
      if (this.album_index == -1) {
        this.album_index = 0;
      }
      if(this.is_playing){
          if(this.album_index != album_id){
          this.is_playing = true;
          this.$refs.mytrack.src = this.albums[album_id].tracks[0];
          this.$refs.mytrack.play();
          this.updateTime = setInterval(this.update,1);
          this.covers[this.album_index].classList.remove("activeTrack");
          this.covers[this.album_index].style = "";
          this.album_index = album_id;
          this.track_index = 0;
          this.$refs.playbox.classList.add("playboxShadow");
          this.setPlays();
          this.$refs.info.innerHTML = this.albums[this.album_index].name + ' - ' + this.albums[this.album_index].artist;
          setTimeout(this.playboxFadeIn, 1000);
          this.$refs.nowplaying.innerText = this.albums[album_id].tracks[0].substring(this.albums[album_id].tracks[0].lastIndexOf('/')+1);
          this.covers[this.album_index].classList.add("activeTrack");
          for(let z=0; z<this.covers.length; z++){
            this.covers[z].style = "";
          }
          this.updateDropdownTracks();
          this.incrementPlay();
        } else {
          this.$refs.mytrack.pause();
          this.is_playing = false;
          window.clearInterval(this.updateTime);
        }
      } else {
        this.is_playing = true;
        if(this.album_index != album_id){
          let album_id = parseInt(event.target.id);
          this.$refs.mytrack.src = this.albums[album_id].tracks[0];
          this.covers[this.album_index].classList.remove("activeTrack");
          this.covers[this.album_index].style = "";
          this.album_index = album_id;
          this.track_index = 0;
          this.$refs.playbox.classList.add("playboxShadow");
          this.setPlays();
          this.$refs.info.innerHTML = this.albums[this.album_index].name + ' - ' + this.albums[this.album_index].artist;
          setTimeout(this.playboxFadeIn, 1000);
          this.$refs.nowplaying.innerText = this.albums[album_id].tracks[0].substring(this.albums[album_id].tracks[0].lastIndexOf('/')+1);
          this.covers[this.album_index].classList.add("activeTrack");
          for(let z=0; z<this.covers.length; z++){
              this.covers[z].style = "";
          }
          this.updateDropdownTracks();
          this.incrementPlay();
        }
        
        this.$refs.mytrack.play();
        this.updateTime = setInterval(this.update,1);
      }
      this.album_index = album_id;
    },

    getAllPlays: async function() {
      fetch("https://music.gproductions.org/API/plays", {method: 'get', headers: { "Content-Type": "application/json" }})
      .then((res) => res.json())
      .then((data) => {
        this.album_plays = data;
        this.gotAlbumPlays = true;
        if (this.album_index != -1) {
          this.setPlays();
          this.$refs.playsBox.classList.remove("noShow");
          this.updateDropdownTracks();
        }
      })
    },

    indicatorClick(e) {
      $('#c-indicators li').removeClass("active");
      var target = e.target || e.srcElement;
      target.classList.add("active");
    },

    devModeFiles(albums) {
      var new_ablums = albums;
      var limit;
      if (!this.devLocal) {
        limit = new_ablums.length;
      }
      else {
        limit = 4;
      }
      for (let x=0; x<limit; x++) {
        if (!this.devLocal) {
          new_ablums[x].cover = "https://gproductions.org/MUSIC/" + new_ablums[x].cover.replace("./", "").trim();  
        } else {
          new_ablums[x].cover = require("@/assets/music/" + new_ablums[x].cover.replace("./", "").trim());
        }
        
        for (let y=0; y<new_ablums[x].tracks.length; y++) {
          if (!this.devLocal) {
            new_ablums[x].tracks[y] = "https://gproductions.org/MUSIC/" + new_ablums[x].tracks[y].replace("./", "").trim(); 
          } else {
            if (new_ablums[x].tracks[y].indexOf('.mp3') == -1) {
              new_ablums[x].tracks.splice(y, 1);
              y--;
              continue;
            }
            new_ablums[x].tracks[y] = require("@/assets/music/" + new_ablums[x].tracks[y].replace("./", "").trim()); 
          }
          
        }
      }

      return new_ablums;
    },

    audioApp: async function() {
      var currentURL = new URL(window.location.href);
      var q_album = currentURL.searchParams.get("album");
      var q_track = currentURL.searchParams.get("track");
      var data = await this.getAlbums();
      if (!this.devMode) {
        this.albums = data.albums;
      } else {
        this.albums = this.devModeFiles(data.albums);
      }

      for (let x=0; x<Math.ceil(this.albums.length/6); x++) {
        var i_li = document.createElement("li");
        i_li.setAttribute("data-target", "#myCarousel");
        i_li.setAttribute("data-slide-to", x + "");


        var div = document.createElement("div");
        div.classList.add("carousel-item");

        var list = document.createElement("ul");
        list.classList.add("myList");
        var make_active = false;
        for (let y=x * 6; y<6 * (x + 1); y++) {
          let album = this.albums[y];
          if (!album) {
            break;
          }
          var li = document.createElement("li");
          var img = document.createElement("img");
          img.src = album.cover;
          img.setAttribute("aria-album", album.name);
          img.className = "slideMe noShow";
          if (q_album && q_album == album.name) {
            make_active = true;
            this.album_index = y;
            if (q_track) {
              for (let t=0; t<album.tracks.length; t++) {
                if (this.album.tracks[t].indexOf(q_track) != -1) {
                  this.track_index = t;
                  break;
                }
              }
            }
          }
          
          img.addEventListener("click", this.switchTrack);
          img.id = y + "";
          
          li.appendChild(img);
          list.appendChild(li);
          img.onload = function(e) {
            e.target.classList.remove("noShow");
          }
        }

        if (make_active || (x==0 && !q_album)) {
          div.classList.add("active");
          i_li.classList.add("active");
        }

        i_li.addEventListener("click", this.indicatorClick.bind(this));

        this.$refs.indicators.appendChild(i_li);
        
        let outer = document.createElement("div");
        outer.classList.add("outer");

        let middle = document.createElement("div");
        middle.classList.add("middle")

        let inner = document.createElement("div");
        inner.classList.add("inner");

        inner.appendChild(list);
        middle.appendChild(inner);
        outer.appendChild(middle);

        div.appendChild(outer);
        this.$refs.carousel_inner.appendChild(div);

      }

      this.covers = document.getElementsByClassName("slideMe");

      this.$refs.mytrack.addEventListener("ended", this.skipSong);
      
      if (q_album) {
        this.is_playing = false;
        this.$refs.mytrack.src = this.albums[this.album_index].tracks[this.track_index];
        this.updateTime = setInterval(this.update,1);
        this.$refs.playbox.classList.add("playboxShadow");
        this.setPlays();
        this.$refs.info.innerHTML = this.albums[this.album_index].name + ' - ' + this.albums[this.album_index].artist;
        setTimeout(this.playboxFadeIn, 1000);
        this.$refs.nowplaying.innerText = this.albums[this.album_index].tracks[this.track_index].substring(this.albums[this.album_index].tracks[this.track_index].lastIndexOf('/')+1);
        this.covers[this.album_index].classList.add("activeTrack");
        for(let z=0; z<this.covers.length; z++){
          this.covers[z].style = "";
        }
        this.updateDropdownTracks();
        await this.sleep(500);
        M.toast({html: 'Click album to play'});
      }

      this.getAllPlays();
    },

    keyboardAction(e) {
      if(e.keyCode =="32"){
        e.preventDefault();
        
        if(!this.$refs.mytrack.paused && !this.$refs.mytrack.ended) {
          this.$refs.mytrack.pause();
          window.clearInterval(this.updateTime);
        
        }
      
        else{
          this.$refs.mytrack.play();
          this.updateTime = setInterval(this.update,1);
        }
      }
      
      if(e.keyCode =="40"){
        e.preventDefault();
        this.skipSong();
      }
      
      if (e.keyCode =="38"){
        e.preventDefault();
        this.lastSong();
      }
      
      if (e.keyCode=='39'){
        e.preventDefault();
        this.$refs.mytrack.currentTime+=10;
      }
      
      if (e.keyCode=='37'){
        e.preventDefault();
        this.$refs.mytrack.currentTime-=10;
      }
      
    }
  }
}
</script>

<style scoped>
@charset "utf-8";
/* CSS Document */

 @font-face { font-family: goth; src: url('/fonts/chubgothic_1.ttf'); }
 @font-face { font-family: babas; src: url('/fonts/BebasNeue.otf'); }
 @font-face { font-family: robotBold; src: url('/fonts/Roboto-BoldCondensed.ttf'); }
 @font-face { font-family: codec_warm; src: url('/fonts/Codec-Warm-Thin-trial.ttf'); }
 @font-face { font-family: codec_cold; src: url('/fonts/Codec-Cold-News-trial.ttf'); }
 @font-face { font-family: robot; src: url('/fonts/Roboto-Light.ttf'); }

 @font-face { font-family: goth2; src: url('../assets/fonts/chubgothic_1.ttf'); }
 @font-face { font-family: babas2; src: url('../assets/fonts/BebasNeue.otf'); }
 @font-face { font-family: robotBold2; src: url('../assets/fonts/Roboto-BoldCondensed.ttf'); }
 @font-face { font-family: codec_warm2; src: url('../assets/fonts/Codec-Warm-Thin-trial.ttf'); }
 @font-face { font-family: codec_cold2; src: url('../assets/fonts/Codec-Cold-News-trial.ttf'); }
 @font-face { font-family: robot2; src: url('../assets/fonts/Roboto-Light.ttf'); }

 @keyframes wakeUP {
	from {opacity: 0}
	to {opacity: 1}
}

svg{
	margin: auto; width: inherit; height: inherit; display: block;
}

.body {
  /* background: white; */
  height: 100vh;
  position: relative;
	padding:0;
  transition: 1s;
}

@font-face { 
		font-family: HelveticaThin;
		src: url('/fonts/HelveticaThin.ttf');
}


@font-face { 
	font-family: SquareWave;
	src: url('/fonts/Paskowy.ttf');
}

@font-face { 
		font-family: HelveticaThin2;
		src: url('../assets/fonts/HelveticaThin.ttf');
}


@font-face { 
	font-family: SquareWave2;
	src: url('../assets/fonts/Paskowy.ttf');
}










header div, main {
 max-width: 89vw;
 height:86vh;
 margin: 0 auto;
 padding: 0em 0em 0em 0em;
 background-color: #F0F0F0;
 z-index:10;
}


header {
 background-image: url(../assets/music/HEADER.png);
 background-repeat: no-repeat;
 background-size: cover;
 height: 6.4vh;
 width: 100%;
 top: 0;
 left:0;
 position: fixed;
 background-color:transparent;
 color:none;
 z-index:10;
}

header h1 {
 float: left;
 font-family: HelveticaThin, HelveticaThin2;
 padding-bottom: 10em;
 z-index:10;
 /*background: url(../url(../header/LinkUpAnchor.png);*/
}

main {
 font-family: HelveticaThin, HelveticaThin2;
 font-size: 20px;
 padding: 5em 1em 1em 1em;
 left: 100px;
 z-index:10;
}

header a {
 text-decoration: none;
 color: white;
 z-index:10;
}

header h1 a {
	background: url(../assets/music/LinkUpAnchor.png);
	z-index:10;
}

header li {
 display: inline-block;
 margin-right: 5em;
 font-family: HelveticaThin, HelveticaThin2;
 z-index:10;
 position:relative;
}

#beatzz{
	color:#479be9;
	z-index:10;
}

header li a:hover {
 color: #479be9;
 z-index:10;
}



header ul {
 float: right;
 list-style-type: none;
 padding-top: -4em;
 font-size:2vh;
 padding-right:7.5em;
 z-index:10;
}

#leggo{
	width:7.2916vw;
	height: 6vh;
	background-image: url(../assets/music/LinkUpAnchorNEW.png);
	background-repeat: no-repeat;
	background-position: center;
	background-size: 100% 94%;
	position: fixed;
	left:6vw;
	top: -0.2vh;
	background-color: transparent;
	border:none;
	z-index:10;
}

.carousel .carousel-item {
    visibility: unset !important;
    width: 100% !important;
    height: unset !important;
    position: relative !important;
    top: unset !important;
	left: unset !important;
	width: inherit !important;
    height: inherit !important;
}

.carousel {
	/* width: fit-content !important; */
	width: 100vw !important;
	margin: auto !important;
}

.carousel-inner {
	/* top: 25%; */
	width: inherit;
  height: inherit;
  position: relative;
}


#toast-container {
	left: 1% !important;
    /* width: 6rem; */
    height: 2rem !important;
    top: 80%;
}

.toast {
	/* top: 0px !important; */
    font-size: 0.9rem !important;
	/* padding: 0.6rem; */
	padding: 0 !important;
	margin-top: 0 !important;
	/* min-height: unset !important; */
    padding-left: 1rem !important;
    font-family: sans-serif !important;
    color: black !important;
    border-radius: 0.8rem !important;
    padding-right: 1rem !important;
    background: rgba(0,0,0, 0) !important;
	box-shadow: 0px 0px 4px grey !important;
	width: fit-content !important;
}







#shareLink {
	width: 0.8rem;
    height: 0.8rem;
    float: right;
    /* position: absolute; */
    right: 6%;
	margin: auto;
	transition: 1s;
	cursor: pointer;
	margin-left: 0.2rem;
}

#shareLink:hover {
	transform: scale(1.3);
	transition: 0.5s;
}

#playbox {
  margin-right: 0 !important;
  right: 1%;
  width: 17%;
  height: fit-content;
  top: 2%;
  margin: 0 !important;
  padding: 0.1rem;
  position: absolute;
  background: transparent;
  border-radius: 3rem;
  opacity: 1;
	padding-left: 1rem;
	padding-right: 1rem;
	z-index: 1000;
	transition: 1s;
}

.playboxShadow {
	box-shadow: 0.1rem 0.1rem 0.4rem rgba(0,0,0,0.15), -0.3rem -0.3rem 0.4rem rgba(255,255,255,0.8);
}

.playboxDrop {
	margin-right: 0 !important;
    right: 1%;
    width: 13%;
    height: auto !important;
    top: 3%;
    margin: 0 !important;
    padding: 0.1rem;
    position: absolute;
    background: #E7E7E7;
    border-radius: 1.4rem !important;
	/* opacity: 0.9 !important; */
	background: rgba(231,231,231, 1) !important;
	box-shadow: 0.1rem 0.1rem 0.2rem rgba(0,0,0,0.3) !important;
    padding-left: 1rem;
    padding-right: 1rem;
	z-index: 1000;
	transition: 1s;
}

#nowplaying {
    font-size: 0.7rem;
    font-family: sans-serif;
    width: 100;
    /* height: 100%; */
    /* position: absolute; */
    display: block;
    white-space: nowrap;
    /* top: 14%; */
    overflow: scroll;
    /* margin: 0 !IMPORTANT; */
    /* margin: auto; */
    margin: 0.5rem;
    /* margin-right: 0.4rem !important; */
    cursor: default;
    transition: 1s;
    margin-left: 0.5rem;
	width: 8.5vw;
	color: black !important;
}

.ellipsis {
	text-overflow: ellipsis;
}

#nowplaying::-webkit-scrollbar {
	display: none;
}

#playstuff {
	display: inline-flex;
  min-width: 100%;
}

#dropdown {
	width: 0.6rem;
    height: 1rem;
	margin: auto;
	cursor: pointer;
	transition: 0.4s;
  margin-left: 1rem;
}

.drop {
	transform: rotateZ(90deg);
}


#anchor{
	width:100vw;
	height: 73px;
	background-image: url(../assets/music/HEADER.png);
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	position: fixed;
	top:-11.8px;
	left:0px;
	z-index:10;
}

#logo{
	width:7.2916vw;
	height: 6vh;
	background-image: url(../assets/music/LinkUpAnchor.png);
	background-repeat: no-repeat;
	background-position: center;
	background-size: 100% 100%;
	position: fixed;
	left:6vw;
	top: -0.2vh;
}

#beatz{
	width:4vw;
	height:3vh;
	position: fixed;
	left: 30vw;
	top: 1.4vh;
	font-family: HelveticaThin, HelveticaThin2;
	color: #ffffff;
	background-color: transparent;
	border: none;
	font-size: 1.9vh;
	
}

#about{
	width:4vw;
	height:3vh;
	position: fixed;
	left: 60vw;
	top: 1.4vh;
	font-family: HelveticaThin, HelveticaThin2;
	color: #479be9;
	background-color: transparent;
	border: none;
	font-size: 1.9vh;
	
}

#wrapper {
	width: 100vw;
	height: 3.5vh;
	position: absolute;
	bottom: 0;
  left: 0px;
	transition: 0.3s ease;
}

.hideWrapper {
  bottom: -3.5vh !important;
  transition: 0.5s !important;
}

nav{
	background-image: url(../assets/music/trackbar.gif);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	width: 100vw;
	height: 3.5vh;
	padding-bottom: 0px;
	padding-left: 0px;
    position:absolute;
	box-shadow: 0px -0.5px 10px #909090;
  display: grid;
}


#defaultBar{
	width: 88vw;
	position:relative;
	background-color: transparent;
	height: 1.4vh;
	/* background-image: url(../assets/music/TimeBar.png); */
	background-size: cover;
	background-repeat: no-repeat;
  margin: auto;
	grid-column: 2;
  grid-row: 1;
}


#progressBar{
	position:absolute;
	bottom: calc(46% - 0.25rem);
	height:0.5rem;
	background-color:transparent;
	background-image: url(../assets/music/TimeElapsed.png);
	background-size: cover;
	background-repeat: no-repeat;
	width: 0px;
	left: 0vw;
	transition: 0.1s;
}

#defaultBar:hover #progressBar {
  height: 0.6rem;
  bottom: calc(50% - 0.3rem);
}

#progressBar:hover {
  height: 0.6rem;
  bottom: calc(50% - 0.3rem);
}

#playButton{
	background-color:transparent;
	border: none;
	height: 32px;
	width: 32px;
	background-image: url(../assets/music/PLAY.png);
	background-repeat: no-repeat;
	background-position: center;
	opacity:0;
	
}

#muteButton{
	background-color:transparent;
	border: none;
	height: 32px;
	width: 32px;
	background-image: url(../assets/music/SOUND.png);
	background-repeat: no-repeat;
	background-position: center;
	opacity:0;
}

#currentTimeDot{
    background-color:transparent;
    height:3.333vh;
    width:1.875vw;
    background-image: url(../assets/music/TIMEDOTSMALL.png);
	background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    left:-1vw;
	opacity:0;
	top: -1vh;
    
    
}

#hoverSkip{
	position: absolute;
    width: 5.5vw;
    height: 3.4vh;
    background-image: url(../assets/music/SKIPHOVER.png);
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent;
    bottom: -0.9vh;
    left: -1.9vw;
    background-size: cover;
    opacity: 0;
}

#hoverBack{
	width: 5.5vw;
    height: 3.4vh;
    background-image: url(../assets/music/SKIPHOVER.png);
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent;
    bottom: -0.9vh;
    left: -2.8vh;
    position: absolute;
    background-size: cover;
    opacity: 0;
}

#skipForward{
	position:relative;
  width:1.5rem;
  height:1.81vh;
  background-image: url(../assets/music/FORWARD.png);
  background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
  background-color: transparent;
  border:none;
  margin: auto;
  grid-column: 3;
  grid-row: 1;
  margin-left: 0;
}

#skipBackward{
  width:1.5rem;
  height: 1.81vh;
  background-image: url(../assets/music/BACK.png);
  background-repeat: no-repeat;
	background-size: contain;
	background-position: center;
  background-color: transparent;
  position: relative;
  border:none;
  margin: auto;
  grid-column: 1;
  grid-row: 1;
  margin-right: 0;
}

#currentTime{
	opacity: 0;
}

#fullDuration{
	opacity:0;
}

#muteButton:active, #playButton:active {
	position:relative;
	top:1px;
	
}



.trackbar {
    color: #959595;
    height: 28.3vh;
    width: 40%;
    font-family: HelveticaThin, HelveticaThin2;
    text-align: left;
    position: relative;
    left: 5.5vw;
    top: 100px;
    margin-bottom: 1rem;
}
.trackbar:nth-child(even) { background:#EEE; }


.backgroundpic {
    height: 25vh;
    width: 16vw;
    position: relative;
    top: 1.4vh;
    left: 50%;
    background-color: transparent;
    background-size: contain;
    filter: blur(11px);
    box-shadow: 0px 0px 0px #ffffff;
    transition: filter 0.5s;
    opacity: 0.35;
}

.playbutton {
    opacity: 1;
    display: block;
    width: 100%;
    height: 28vh;
    margin: 0px 0px 0px 0px;
    background: url(../assets/music/PLAINWHITE.png) no-repeat;
    border: none;
    cursor: pointer;
    outline: none;
    position: absolute;
    top: 0px;
    background-size: cover;
}

.trackname {
    color: #ffffff;
    font-size: 12px;
    position: absolute;
    float: left;
    text-align: left;
    left: 5%;
    bottom: 10%;
}




.slideMe{
    width: 100px;
    height: 100px;
    max-width: 220px !important;
    max-height: 220px !important;
    min-height: 80px !important;
	min-width: 80px !important;
	opacity: 1;
    transition: 0.3s ease-out;
}

li{
    display: inline-block;
    position: relative;
}

.myContainer{
	margin: 0 auto;
    top: 26vh;
    position: relative;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.visualizer{
    width: 900px;
    height: 46px;
    top: 13%;
    opacity: 0.8;
    left: 13%;
    position: absolute;
}

#info_out {
	height: 6.5%;
  position: absolute;
  top: 2%;
  left: 1%;
  color: rgb(49, 49, 49);
  font-family: SquareWave, SquareWave2;
  /* background: rgb(238, 238, 238); */
  transition: 1s;
}

#info{
	position: relative;
	padding: 10px 10px 0px;
	text-align: center;
	font-size: 1.2rem;
	/* box-shadow: 5px 10px 12px #acacac; */
	transition: 1s;
	opacity: 0.89;
	/* opacity: 0 !important; */
}

.noHeight {
  height: 0 !important;
}


@media (max-width: 1023px) {

	#myList{
		position: absolute;
		top: 26%;
		left: 10%;
		width: 100vw;
		float: left;
	}

	.myContainer{
		position: absolute;
		top: 6%;
		right: 0%;
	}

	/* li{
		display: inline;
		position: relative;
	} */

	.slideMe{
		width: 22%;
		height: 22%;
		position: relative;
		max-width: 48.4% !important;
		max-height: 48.4% !important;
		min-height: 17.6% !important;
		min-width: 17.6% !important;
		opacity: 1;
		transition: 0.3s ease-out;
	}

	.activeTrack{
		width: 44% !important;
		height: 44% !important;
		transition: 0.3s ease-out;
	}

}




















@media (min-width: 1189px){
	
.parallex{
	background-size: cover;
	background-attachment: fixed;
	height: 100vh;
	opacity: 0;
	
}

@keyframes fadeEffect {
	from {opacity: 0;}
	to {opacity: 1;}
}

#G{
	top: 40%;
	position: absolute;
	left: 40%;
	font-family: goth, goth2;
	font-size: 3.75vw;
	color: #FBFBFB;
	animation: fadeEffect 1.5s;
}


#work{
	height: 50px;
	width: 100%;
	background: #111;
}

#work ul {
	padding-top: 10px;

}

#work ul li{
	list-style: none;
	display: inline-block;
	
}

#work li:hover{
	background: white;
}

#work a:hover {
	
	
	color: black;
}


#work ul li a {
	
	background-color: none;
	text-decoration: none;
	color: white;
	font-family: babas, babas2;
	font-size: 1.53vw;
	padding: 0px 55px 0px 55px;
	
}


#sidebar {
	display: none;
}

}




@media (min-width: 1025px) and (max-width: 1188px){
	
.parallex{
	background-size: cover;
	background-attachment: fixed;
	height: 100vh;
	opacity: 0;
	
}

@keyframes fadeEffect {
	from {opacity: 0;}
	to {opacity: 1;}
}

#G{
	top: 40%;
	position: absolute;
	left: 40%;
	font-family: goth, goth2;
	font-size: 3.75vw;
	color: #FBFBFB;
	animation: fadeEffect 1.5s;
}


#work{
	height: 66px;
	width: 100%;
	background: #111;
}

#work ul {
	padding-top: 16px;

}

#work ul li{
	list-style: none;
	display: inline-block;
	
}

#work li:hover{
	background: white;
}

#work a:hover {
	
	
	color: black;
}


#work ul li a {
	
	background-color: none;
	text-decoration: none;
	color: white;
	font-family: babas, babas2;
	font-size: 1.6vw;
	padding: 0px 60px 0px 60px;
	
}


#sidebar {
	display: none;
}

}



@media (min-width: 706px) and (max-width: 1024px){
	
.parallex{
	background-size: cover;
	background-attachment: fixed;
	height: 100vh;
	opacity: 0;
	
}

@keyframes fadeEffect {
	from {opacity: 0;}
	to {opacity: 1;}
}

#G{
	top: 40%;
	position: absolute;
	left: 40%;
	font-family: goth, goth2;
	font-size: 3.75vw;
	color: #FBFBFB;
	animation: fadeEffect 1.5s;
}


#work{
	height: 66px;
	width: 100%;
	background: #111;
}

#work ul {
	padding-top: 16px;

}

#work ul li{
	list-style: none;
	display: inline-block;
	
}

#work li:hover{
	background: white;
}

#work a:hover {
	
	
	color: black;
}


#work ul li a {
	
	background-color: none;
	text-decoration: none;
	color: white;
	font-family: babas, babas2;
	font-size: 1.6vw;
	padding: 0px 45px 0px 45px;
	
}


#sidebar {
	display: none;
}

}





@media (min-width: 601px) and (max-width: 705px){
	
.parallex{
	background-size: cover;
	background-attachment: fixed;
	height: 100vh;
	opacity: 0;
	
}

@keyframes fadeEffect {
	from {opacity: 0;}
	to {opacity: 1;}
}

#G{
	top: 40%;
	position: absolute;
	left: 40%;
	font-family: goth, goth2;
	font-size: 3.75vw;
	color: #FBFBFB;
	animation: fadeEffect 1.5s;
}


#work{
	height: 66px;
	width: 100%;
	background: #111;
}

#work ul {
	padding-top: 16px;

}

#work ul li{
	list-style: none;
	display: inline-block;
	
}

#work li:hover{
	background: white;
}

#work a:hover {
	
	
	color: black;
}


#work ul li a {
	
	background-color: none;
	text-decoration: none;
	color: white;
	font-family: babas, babas2;
	font-size: 1.6vw;
	padding: 0px 30px 0px 30px;
	
}


#sidebar {
	display: none;
}

}




@media (min-width: 501px) and (max-width: 600px){
	
.parallex{
	background-size: cover;
	background-attachment: fixed;
	height: 100vh;
	opacity: 0;
	
}

@keyframes fadeEffect {
	from {opacity: 0;}
	to {opacity: 1;}
}

#G{
	top: 40%;
	position: absolute;
	left: 40%;
	font-family: goth, goth2;
	font-size: 3.75vw;
	color: #FBFBFB;
	animation: fadeEffect 1.5s;
}


#work{
	height: 66px;
	width: 100%;
	background: #111;
}

#work ul {
	padding-top: 16px;

}

#work ul li{
	list-style: none;
	display: inline-block;
	
}

#work li:hover{
	background: white;
}

#work a:hover {
	
	
	color: black;
}


#work ul li a {
	
	background-color: none;
	text-decoration: none;
	color: white;
	font-family: babas, babas2;
	font-size: 2vw;
	padding: 0px 20px 0px 20px;
	
}


#sidebar {
	display: none;
}

}





@media (max-width: 500px) {
	
.parallex{
	background-size: cover;
	background-attachment: fixed;
	height: 100vh;
	
}
	
#G{
	top: 40%;
	position: absolute;
	left: 39%;
	font-family: goth, goth2;
	font-size: 6vw;
	color: #FBFBFB;
}

#work {
	display: none;
}

#sidebar {
	position: fixed;
	width: 90px;
	height: 100%;
	background: #111;
	left: -90px;
	transition: all 300ms ease-out;
}

#sidebar.active {
	left: 0px;
}

#sidebar ul li{
	text-align: center;
	padding: 30px 0px 30px 0px;
	
	
}

#sidebar ul li a {
	color: white;
	list-style: none;
	text-decoration: none;
	font-family: babas, babas2;
	font-size: 4.5vw;
	
	
}


}

</style>

<style>
.noDisplay {
	display: none !important;
}

.noShow {
	opacity: 0 !important;
}

.slideMe{
    width: 100px;
    height: 100px;
    max-width: 220px !important;
    max-height: 220px !important;
    min-height: 80px !important;
	min-width: 80px !important;
	opacity: 1;
    transition: 0.3s ease-out;
}

.myList {
	width: fit-content;
  list-style-type: none;
  display: flex;
  margin: auto;
  padding-left: 0;
  list-style-type: none;
  width: fit-content;
  margin: auto;
  padding: 0;
  box-sizing: inherit;
  display: block;
  list-style-type: disc;
}

.myList li {
  display: inline-block;
  position: relative;
  list-style-type: none;
}

.outer {
	display: table;
	position: relative;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
}
  
  .middle {
	display: table-cell;
	vertical-align: middle;
  }
  
  .inner {
	margin-left: auto;
	margin-right: auto;
	/* width: fit-content; */
	position: relative;
	/*whatever width you want*/
  }

.carousel-indicators {
  position: absolute !important;
  margin-top: 3rem;
  bottom: 33% !important;
}

.activeTrack{
    width: 200px;
    height: 200px;
    transition: 0.2s ease-out;
}

#tracks {
	max-height: 70vh;
	overflow: scroll;
	scroll-behavior: smooth;
  padding-left: 0;
  list-style-type: none;
}


#tracks::-webkit-scrollbar {
	width: 1px;
}
  
  /* Track */
#tracks::-webkit-scrollbar-track {
	border-radius: 3px;
}
  
  /* Handle */
#tracks::-webkit-scrollbar-thumb {
	background: rgba(0,0,0,0.3);
}

#tracks::-webkit-scrollbar-corner {
	display: none;
}

li.track {
  margin: 0;
  padding: 0;
  display: block;
  /* margin: 0.5rem; */
  border-bottom: 0px solid;
  margin-left: 1rem;
  /* display: list-item; */
  display: flex;
  margin-top: 0.7rem;
  transition: 0.5s;
}

.title {
    font-size: 0.7rem;
    font-family: sans-serif;
    color: #555;
    cursor: pointer;
    transition: 0.5s;
    width: 80%;
}

.title:hover {
	color: black;
	transition: 0.1s;
}


#playsBox {
	display: flex;
    margin-right: 1rem;
    /* border: 1px solid darkgray; */
    border-radius: 0.4rem;
    height: 1.5rem;
	margin: auto;
	margin-left: 0.5rem;
	margin-right: 1rem;
	transition: 1s;
}

#plays {
	font-size: 0.7rem;
	margin: auto;
	margin-right: 0.2rem;
    /* border: 2px black solid;*/
}

#playsBox svg {
	width: 0.7rem;
    /* margin-top: 0.4rem; */
	/* padding-left: 0.1rem; */
	margin: auto;
	display: block;
    opacity: 0.6;
}



.playsBox2 {
	display: flex;
    /* margin-right: 1rem; */
    /* border: 1px solid darkgray; */
    /* border-radius: 0.4rem; */
    height: 1rem;
    /* margin: auto; */
    /* margin-left: 1rem; */
    /* margin-right: 1rem; */
    /* position: absolute; */
    /* right: 0; */
	float: right;
	opacity: 0.6;
}

.plays2 {
	font-size: 0.6rem;
    /* margin: auto; */
    /* border: 2px black solid; */
}

.playsBox2 svg {
	width: 0.5rem;
  /* margin-top: 0.4rem; */
  /* padding-left: 0.1rem; */
  margin-left: 0.1rem;
  opacity: 0.66;
  height: auto;
}

.carousel-inner {
  display: flex;
}
</style>