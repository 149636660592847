<template>
  <div ref="film_body" style="opacity: 0" class="body">
    <div id="filmSpace">
      <div id="wholegal" ref="wholegal" class="fadeGal">
        <a class="prev" @click="changeSlides(-1)">&#10094;</a>  
        
        <div class="slideshow-container">
          <div class="mySlides display">
            <!-- <iframe id="test" allowtransparency="true" align="center" style="background: #100f0c; border-color: black" width="100%" height="100%" src="https://www.youtube.com/embed/f2pTdoRo0io?rel=0" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe> -->
            <!-- <iframe width="560" height="315" src="https://www.youtube.com/embed/f2pTdoRo0io" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> -->
            <iframe  id="player0" width="560" height="315" style="width: 100%; height: 100%; border-radius: 2px;" src="https://www.youtube.com/embed/f2pTdoRo0io?rel=0" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            <img id="cover" style="border-radius: 2px" :src="require('../assets/film/prodigal.jpg')" class="cover1 theCover" @click="playVideo(0)">
          </div>
              
          <div class="mySlides">
            <!-- <iframe id="tt" allowtransparency="true" align="center" style="background: #100f0c; border-color: black" width="100%" height="100%" src="https://www.youtube.com/embed/OcssvW3MHpU?rel=0" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe> -->
            <iframe id="player1" width="560" height="315" style="width: 100%; height: 100%" src="https://www.youtube.com/embed/OcssvW3MHpU?rel=0" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            <img id="cov" :src="require('../assets/film/dwb3.jpg')" class="cover2 theCover" @click="playVideo(1)">
          </div>
        </div>

        <a class="next" @click="changeSlides(1)">&#10095;</a>
      </div>

    </div>

    <div id="description" ref="description" class="noOpacitySlide noOpacity">
      <p>DESCRIPTION:</p>
      <p style="font-size: 0.6rem;">CTEC award winning short film, telling the story about a son coming home</p>
      <p style="font-size: 0.6rem;" class="noDisplay">Driving While Black is a visual poem describing society's flaws in equality and a motivation for change.</p>
    </div>

    <img id="background2" ref="background2" class="noDisplay noBrightness" src="../assets/film/GFilm.gif" @load="gifLoadedFunc()">
    <img id="background" class="noDisplay noOpacity noBrightness" ref="tmp_background" src="../assets/film/GFilm2.png" @load="backgroundLoaded('#background')">
  
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */

import $ from 'jquery'

export default {
  name: 'FILM',
  props: {
    pageMessages: Object,
    thisPage: Number
  },
  data: function() {
    return {
      currentSlide: 0,
      inView: false,
      gifLoaded: false
    }
  },

  watch: {
    'inView': async function(newView, oldView) {
      if (newView == true) {
        await this.sleep(450);
        this.$refs.wholegal.classList.remove("fadeGal");
        
        if (this.gifLoaded) {
          await this.sleep(200);
          this.$refs.background2.classList.remove("noDisplay");
          await this.sleep(200);
          this.$refs.background2.classList.remove("noBrightness");
          this.$refs.tmp_background.classList.add("noOpacity");
        } else {
          this.$refs.tmp_background.classList.remove("noBrightness");
        }

        this.$refs.description.classList.remove("noOpacitySlide");

      } else {
        // await this.sleep(500);
        // this.$refs.background2.classList.add("noDisplay");
        this.$refs.wholegal.classList.add("fadeGal");
        this.$refs.description.classList.add("noOpacitySlide");
        this.$refs.tmp_background.classList.add("noOpacity");
        this.$refs.background2.classList.add("noBrightness", "noDisplay");
        // this.$refs.background2.classList.add("noBrightness");
        // if (this.gifLoaded) {
        //   await this.sleep(400);
        //   // await this.sleep(450);
        //   this.$refs.background2.classList.add("noOpacity");
        
        // }
      }
    },

    'currentSlide': async function(newSlide, oldSlide) {
      
      document.getElementsByClassName('mySlides')[oldSlide].classList.add("noOpacity");
      this.$refs.description.classList.add("noOpacitySlide");
      await this.sleep(400);
      document.getElementsByClassName('mySlides')[oldSlide].classList.remove("display");
      this.$refs.description.getElementsByTagName("p")[oldSlide+1].classList.add("noDisplay");
      document.getElementsByClassName('mySlides')[newSlide].classList.add("display");
      this.$refs.description.getElementsByTagName("p")[newSlide+1].classList.remove("noDisplay");
      await this.sleep(400);
      document.getElementsByClassName('mySlides')[newSlide].classList.remove("noOpacity");
      this.$refs.description.classList.remove("noOpacitySlide");
    }
  },

  created() {
    this.$root.$on(this.pageMessages[this.thisPage][0], this.scrolledTo);
    this.$root.$on(this.pageMessages[this.thisPage][1], this.scrolledAway);
  },

  mounted: async function() {
    this.$refs.film_body.style.setProperty("opacity", 1);
    this.scrolledTo();
  },
  methods: {
    sleep(milliseconds) {
      return new Promise(function(resolve, reject) {
        setTimeout(resolve, milliseconds, true);
      });
    },

    gifLoadedFunc: async function() {
      if (!this.gifLoaded) {
        this.gifLoaded = true
        this.$refs.background2.classList.remove("noDisplay");
        await this.sleep(1000);
        this.$refs.background2.classList.remove("noBrightness");
        await this.sleep(1700);
        this.$refs.tmp_background.classList.add("noOpacity");
      }
    },

    scrolledTo() {
      this.inView = true;
    },

    scrolledAway() {
      this.inView = false;
    },

    backgroundLoaded: async function(id) {
      if ($(id).hasClass('noDisplay')) {
        $(id).removeClass("noDisplay");
        await this.sleep(100);
      }
      $(id).removeClass("noOpacity");
      $(id).removeClass("noBrightness");
    },

    changeSlides(diff) {
      let slides = document.getElementsByClassName("mySlides");
      if (this.currentSlide + diff >= slides.length) {
        this.currentSlide = 0;
      } else if (this.currentSlide + diff < 0) {
        this.currentSlide = slides.length - 1;
      } else {
        this.currentSlide += diff;
      }
    },

    playVideo(index) {
      let iframe = document.getElementsByTagName('iframe')[index];
      iframe.src += "&autoplay=1";
      document.getElementsByClassName("theCover")[index].classList.add("noOpacity");
      setTimeout(function() {
        document.getElementsByClassName("theCover")[index].classList.add("noDisplay");
      }, 1000)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
@font-face { font-family: helvetica_neue; src: url('/fonts/HelveticaNeue.ttf'); }
@font-face { font-family: helvetica_neue2; src: url('../assets/fonts/HelveticaNeue.ttf'); }

.display {
  display: block !important;
}

.noDisplay {
  display: none !important;
}

.noOpacity {
  opacity: 0;
}
</style>

<style scoped>
.body {
  /* background: black; */
  height: 100vh;
  position: relative;
}

#background2 {
  width: 90vw;
  height: auto;
  position: absolute;
  bottom: 0;
  transition: opacity 0.3s, filter 2s ease-out;
  filter: brightness(1);
  left: calc(50vw - 90vw/2)
}

#background {
  width: 90vw;
  height: auto;
  position: absolute;
  bottom: 0;
  transition: opacity 0.3s, filter 1.5s ease-out;
  filter: brightness(1);
  left: calc(50vw - 90vw/2)
}

.noOpacity {
  opacity: 0;
}


@keyframes fadeEffect {
	from {opacity: 0;}
	to {opacity: 1;}
}

@keyframes fadeEffectArrow {
	from {opacity: 0;}
	to {opacity: 0.55;}
}

#filmSpace {
  position: absolute;
  width: 100vw;
  top: 0;
  height: 50%;
}

#wholegal{
	position: relative;
  width: 50vw;
  height: calc(50vw * 0.48);
  /* min-width: 50%; */
  /* min-height: 30%; */
  top: 35%;
  /* left: 30%; */
  margin: auto;
  transition: all 2s ease, filter 0.8s ease;
}

.noBrightness {
  filter: brightness(0) !important;
}


.slideshow-container {
  width: 100%;
  position: relative;
  margin: auto;
  height: 100%;
  z-index: 3;
  
}

.cover1{
	width: 100.1%;
	height: 100%;
	background-size: cover;
	position: absolute;
	left:0%;
	top: 0px;
  z-index: 2;
  filter: brightness(1.1) contrast(1.2);
	transition: 1s;
}

.cover2{
	width: 100.1%;
	height: 100%;
	background-size: cover;
	position: absolute;
	left:0%;
	top: 0px;
	z-index: 2;
  filter: contrast(1.2) brightness(1);
  transition: 1s;
}
	

/* Hide the images by default */
.mySlides {
  display: none;
  height: 100%;
  transition: 0.4s;
}

#first {
	display:block;
}

/* Next & previous buttons */
.prev, .next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  margin-top: -22px;
  padding: 16px;
  color: rgba(255,255,255,0.2);
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  z-index: 10;
}

.prev {
  left: -20%;
}

/* Position the "next button" to the right */
.next {
  right: -20%;
  border-radius: 3px 0 0 3px;
}

.next:hover, .prev:hover {
  transform: scale(1.2);
}

/* On hover, add a black background color with a little bit see-through */
.prev:hover, .next:hover {
  color: white;
}

/* Caption text */
.text {
  color: #f2f2f2;
  font-size: 15px;
  padding: 8px 12px;
  position: absolute;
  bottom: 8px;
  width: 100%;
  text-align: center;
  display: none;
}

/* Number text (1/3 etc) */
.numbertext {
  color: #f2f2f2;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
  display: none;
}

/* The dots/bullets/indicators */
.dot {
  cursor:pointer;
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #696969;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
  
}

#thedots{
	position: relative;
	top: 5%;
	
}

.active, .dot:hover {
  background-color: #bbb;
}

/* Fading animation */
.fade {
  -webkit-animation-name: fade;
  -webkit-animation-duration: 3s;
  animation-name: fade;
  animation-duration: 3s;
}

.fadeGal {
  opacity: 0;
  transform: scale(0.95);
  filter: brightness(0.8);
  transition: opacity 1s ease, transform 2s ease !important;
}

#description {
  margin: 2rem;
  text-align: right;
  color: white;
  font-family: helvetica_neue, helvetica_neue2;
  font-size: 1rem;
  position: absolute;
  width: 25%;
  /* margin-top: 6rem; */
  transition: 0.4s;
  bottom: 0;
  z-index: 100;
  right: 0;
  letter-spacing: 3px;
  /* display: none !important; */
  -webkit-font-smoothing: auto;
  /* display: none !important; */
}

.noOpacitySlide {
  opacity: 0;
  transform: translateX(10px);
}
</style>
