import Vue from 'vue'
import App from './App.vue'
import vueResource from 'vue-resource'
import VueObserveVisibility from 'vue-observe-visibility'

Vue.config.productionTip = false

window.$ = window.jQuery = require('jquery');
Vue.use(vueResource);
Vue.use(VueObserveVisibility);

new Vue({
  render: h => h(App),
}).$mount('#app')
