<template>
  <div class="body">
    <img id="background" class="noOpacity" :src="require('../assets/film/g_film_mobile_temp.png')" @load="backgroundLoaded('#background')">

    <div id="filmSpace">
      <div id="wholegal" ref="wholegal" class="fadeGal">
        
        <div class="slideshow-container">
          <div class="mySlides fade display">
            <img id="cover" :src="require('../assets/film/prodigal_mobile_poster.png')" onclick="window.open('https://youtu.be/f2pTdoRo0io','mywindow');" class="cover1 theCover">
          </div>
              
          <div class="mySlides fade">
            <img id="cov" :src="require('../assets/film/dwb_mobile_poster.png')" onclick="window.open('https://youtu.be/OcssvW3MHpU','mywindow');" class="cover2 theCover">
          </div>
        </div>

        <div id="description" ref="description">
            <p>CTEC award winning short film, telling the story about a son coming home</p>
            <p class="noDisplay">Driving While Black is a visual poem describing society's flaws in equality and a motivation for change.</p>
        </div>

        <div id="thedots" style="text-align:center">
            <div class="dotout">
                <span class="dot" :class="{active: currentSlide == 0}" @click="currentSlide = 0"></span> 
            </div>

            <div class="dotout">
                <span class="dot" :class="{active: currentSlide == 1}" @click="currentSlide = 1"></span> 
            </div>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
/* eslint-disable no-unused-vars */

import $ from 'jquery'

export default {
  name: 'FILM',
  props: {
    pageMessages: Object,
    thisPage: Number
  },
  data: function() {
    return {
      currentSlide: 0,
      inView: false,
      gifLoaded: false
    }
  },

  watch: {
    'inView': async function(newView, oldView) {
      if (newView == true) {
        await this.sleep(200);
        this.$refs.wholegal.classList.remove("fadeGal");
      } else {
        this.$refs.wholegal.classList.add("fadeGal");
      }
    },

    'currentSlide': async function(newSlide, oldSlide) {
      document.getElementsByClassName('mySlides')[oldSlide].classList.add("noOpacity");
      this.$refs.description.classList.add("noOpacity");
      await this.sleep(200);
      document.getElementsByClassName('mySlides')[oldSlide].classList.remove("display");
      this.$refs.description.getElementsByTagName("p")[oldSlide].classList.add("noDisplay");
      document.getElementsByClassName('mySlides')[newSlide].classList.add("display");
      this.$refs.description.getElementsByTagName("p")[newSlide].classList.remove("noDisplay");
      await this.sleep(200);
      document.getElementsByClassName('mySlides')[newSlide].classList.remove("noOpacity");
      this.$refs.description.classList.remove("noOpacity");
    }
  },

  created() {

  },

  mounted() {
    this.$root.$on(this.pageMessages[this.thisPage][0], this.scrolledTo);
    this.$root.$on(this.pageMessages[this.thisPage][1], this.scrolledAway);
  },
  methods: {
    sleep(milliseconds) {
      return new Promise(function(resolve, reject) {
        setTimeout(resolve, milliseconds, true);
      });
    },

    scrolledTo() {
      console.log("SCROLLED TO");
      this.inView = true;
    },

    scrolledAway() {
      console.log("SCROLLED AWAY");
      this.inView = false;
    },

    backgroundLoaded(id) {
      $(id).removeClass("noOpacity");
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
@font-face { font-family: helvetica_neue; src: url('/fonts/HelveticaNeue.ttf'); }
@font-face { font-family: helvetica_neue2; src: url('../assets/fonts/HelveticaNeue.ttf'); }

.display {
  display: block !important;
}

.noDisplay {
  display: none !important;
}

.noOpacity {
  opacity: 0;
}
</style>

<style scoped>
.body {
  /* background: black; */
  height: 100vh;
  position: relative;
}

#background3 {
  width: 100vw;
  height: auto;
  position: absolute;
  bottom: 0;
  transition: 0.3s;
  filter: brightness(1.5);
}

#background {
  width: 100vw;
  height: auto;
  position: absolute;
  bottom: 0;
  transition: 0.3s;
  filter: blur(6px) brightness(1.5);
}

.noOpacity {
  opacity: 0;
}


@keyframes fadeEffect {
	from {opacity: 0;}
	to {opacity: 1;}
}

@keyframes fadeEffectArrow {
	from {opacity: 0;}
	to {opacity: 0.55;}
}

#filmSpace {
  position: relative;
  width: 100vw;
  top: 0;
  height: 100%;
}

#wholegal{
  position: relative;
  width: auto;
  height: 43vh;
  /* min-width: 50%; */
  /* min-height: 30%; */
  top: 18%;
  /* left: 30%; */
  margin: auto;
  transition: 1s;
}


.slideshow-container {
  width: 100%;
  position: relative;
  margin: auto;
  height: 100%;
  z-index: 3;
  
}

.cover1{
    width: auto;
    height: 100%;
    background-size: cover;
    /* position: absolute; */
    left: 0%;
    top: 0px;
    z-index: 2;
    filter: brightness(0.8);
}

.cover2{
	width: auto;
    height: 100%;
    background-size: cover;
    /* position: absolute; */
    left: 0%;
    top: 0px;
    z-index: 2;
}
	

/* Hide the images by default */
.mySlides {
  display: none;
  height: 100%;
  text-align: center;
  transition: 0.4s;
}

.mySlides img {
    box-shadow: 0px 0px 3px black;
}

#first {
	display:block;
}

/* Next & previous buttons */
.prev, .next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  margin-top: -22px;
  padding: 16px;
  color: rgba(255,255,255,0.2);
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  z-index: 10;
}

.prev {
  left: -20%;
}

/* Position the "next button" to the right */
.next {
  right: -20%;
  border-radius: 3px 0 0 3px;
}

.next:hover, .prev:hover {
  transform: scale(1.2);
}

/* On hover, add a black background color with a little bit see-through */
.prev:hover, .next:hover {
  color: white;
}

/* Caption text */
.text {
  color: #f2f2f2;
  font-size: 15px;
  padding: 8px 12px;
  position: absolute;
  bottom: 8px;
  width: 100%;
  text-align: center;
  display: none;
}

/* Number text (1/3 etc) */
.numbertext {
  color: #f2f2f2;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
  display: none;
}

/* The dots/bullets/indicators */
.dot {
  cursor:pointer;
  height: 10px;
  width: 10px;
  background-color: #696969;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
  
}

.dotout {
    padding: 1rem;
    display: inline;
}

#thedots{
	position: relative;
	top: 5%;
	
}

.active, .dot:hover {
  background-color: #bbb;
}

/* Fading animation */
.fade {
  -webkit-animation-name: fade;
  -webkit-animation-duration: 3s;
  animation-name: fade;
  animation-duration: 3s;
}

.fadeGal {
  opacity: 0;
  transform: scale(0.9);
}

#description {
  margin: auto;
  text-align: center;
  color: white;
  font-family: helvetica_neue, helvetica_neue2;
  font-size: 0.6rem;
  height: 3rem;
  margin-top: 2rem;
  transition: 0.3s;
  width: 80%;
}


@media (orientation: landscape) {
  #background {
    width: 100vw;
    height: auto;
  }

  .body {
    overflow: hidden;
  }
}
</style>
